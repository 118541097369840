<template>
  <!--  :item-text="item => `${item.id} - ${item.firstName} ${item.lastName} (${item.internalName})`"  -->
  <!--  item-value="id"   -->
  <!--  @click:append="searchDriverApi()"   -->
  <!--  :item-text="(item) => item.id + ' - ' + item.firstName + ' ' + item.lastName" -->
  <v-autocomplete
    :value="value"
    item-value="id"
    ref="autocompleteDriver"
    @input="updateValue($event)"
    :items="items"
    :loading="isLoading"
    :search-input.sync="searchDriver"
    hide-selected
    :label="label"
    :placeholder="placeholder"
    clearable
    auto-select-first
    disable-lookup
    :dense="dense"
    :outlined="outlined"
    :filled="filled"
    :disabled="disabled"
    :single-line="singleLine"
    :hide-details="hideDetails"
    :filter="filter"
    v-bind="searchParams"
  >
    <!--     :prepend-icon="prependIcon"
    @click:prepend="searchDriverApi()"
    -->
    <!--     :append-outer-icon="sortIcon"
    @click:append-outer="sortItemSwitch()"
    -->
    <template v-slot:selection="{ item }">
      {{ displayOnlyForAdmin ? item.id + " | " : "" }}
      {{ item.vehicleInternalName ? item.vehicleInternalName + " | " : "" }}
      {{ item.internalName ? item.internalName + " - " : "" }}
      {{ item.firstName }} {{ item.lastName }}
    </template>

    <template v-slot:item="{ item }">
      <template v-if="!item.vehicleInternalName">-</template>
      <template v-if="item.workStatus !== 'OFFLINE'">.</template>
      <span :class="item.workStatus !== 'OFFLINE' ? 'font-weight-bold' : ''"
        >{{ displayOnlyForAdmin ? item.id + " | " : "" }}
        {{ item.vehicleInternalName ? item.vehicleInternalName + " | " : "" }}
        {{ item.internalName ? item.internalName + " - " : "" }}
        {{ item.firstName }} {{ item.lastName }}
      </span>
      <template v-if="item.status !== 'ENABLED'"> x </template>
    </template>
    <template v-slot:prepend>
      <v-icon
        @click="searchDriverApi()"
        :title="$t('AutocompleteDriverRemote.prependIconTitle')"
        >{{ prependIcon }}</v-icon
      >
    </template>
    <template slot="append-outer">
      <v-icon
        @click="sortItemSwitch()"
        :title="$t('AutocompleteDriverRemote.iconAppendOuterTitle')"
        >{{ sortIcon }}</v-icon
      >
    </template>
  </v-autocomplete>
</template>

<script>
import axios from "axios";
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      //default: this.$t("AutocompleteDriverRemote.label"),
      default: "Driver",
    },
    placeholder: {
      type: String,
      //default: this.$t("AutocompleteDriverRemote.placeholder"),
      default: "Start write...",
    },
    prependIcon: {
      type: String,
      default: "fas fa-user-tie",
    },

    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    searchParams: {
      type: Object,
      //default: true,
    },
  },
  data() {
    return {
      items: [],
      /*   item: {
        active: "ACTIVE",
        email: "string",
        firstName: "string",
        id: 0,
        internalName: "string",
        lastName: "string",
        phone: "string",
        status: "ARCHIVED",
        vehicleInternalName: "string",
      },*/

      isLoading: false,
      model: null,
      sortBy: "vehicleInternalNameActive",
      sortIcon: "mdi-car",
      searchDriver: null,

      searchData: {
        all: true,
      },

      debounce: null,

      //zobrazenie vybranych objektov iba pre admina
      displayOnlyForAdmin: false,

      //Pre rozsirenie udajov o internalName vozidla
      //All operational data
      getAllItemsData: {
        showOnlyActiveOrder: true,
        limitOrders: 1,
        countDriverActiveOrders: false,
        messageMinDatetime: null,
        limitMessages: 1,
        showWorklogRequests: false,
      },
    };
  },

  created() {},

  mounted() {
    this.getUserRole();
    this.searchDriverApi();
    this.refreshTimerOperational = setInterval(() => {
      this.searchDriverApi();
    }, 600000);
  },

  computed: {},

  watch: {
    searchDriver(value) {
      //zablokovane vyhladavanie zo servera
      return;

      //Ak nie je ziadna hodnota, nevyhladavat
      if (!value) {
        return;
      }
      //Vyhladat zo servera iba pri zadani prveho znaku, potom udaje filtrovat v autocomplete poli
      if (value.length > 1) {
        return;
      }
      // this.clearEntries();
      //this.searchData.all = this.searchParams.type;
      //this.searchAddressApi();
      this.isLoading = true;
      this.fetchEntriesDebounced();
    },
  },

  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },

    updateValue(value) {
      //console.log("updateValue: ", value);
      this.$emit("input", value);
    },

    filter(item, queryText, itemText) {
      return (
        (!item.vehicleInternalName ? "-" : "") +
        (item.workStatus !== "OFFLINE" ? "." : "") +
        item.workStatus +
        " " +
        item.id +
        " " +
        item.vehicleInternalName +
        " " +
        item.internalName +
        " " +
        item.firstName +
        " " +
        item.lastName
      )
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          queryText
            .toLocaleLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    },

    searchDriverApi() {
      this.isLoading = true;
      //console.log("search: ", this.searchData.query),
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/forPicker`,
          {
            params: this.searchData,
          }
        )
        .then((res) => {
          //console.log(res);
          var data = res.data;
          this.items = data;
          //call function to add vehicle info to driver with delay 1s
          setTimeout(() => {
            this.addVehicleInfoToDriver();
          }, 1000);
          setTimeout(() => {
            this.sortItems();
          }, 2000);
          //ulozit si zoznam vodicov do localstorage
          //Informativne ho budu pouzivat ine stranka
          if (data) {
            if (data.length > 0) {
              //loop through data and created new array with only id and internalName, firstName, lastName
              var driverList = [];
              for (var i = 0; i < data.length; i++) {
                var driver = {
                  id: data[i].id,
                  internalName: data[i].internalName,
                  firstName: data[i].firstName,
                  lastName: data[i].lastName,
                };
                driverList.push(driver);
              }
              localStorage.setItem("driverList", JSON.stringify(driverList));
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    addVehicleInfoToDriver() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/operationalData`,
          {
            params: this.getAllItemsData,
          }
        )
        .then((response) => {
          //console.log(res);
          var driverList = response.data.driverList;
          //add vehicle info (driverList.vehicle.internalName) to this.items by id
          for (var i = 0; i < this.items.length; i++) {
            for (var j = 0; j < driverList.length; j++) {
              if (this.items[i].id === driverList[j].id) {
                this.items[i].vehicleInternalName =
                  driverList[j].vehicle.internalName;
                //console.log("this.items[i].vehicleInternalName: ", this.items[i].vehicleInternalName);
                break;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    sortItemSwitch() {
      if (this.sortBy === "lastNameActive") {
        this.sortBy = "vehicleInternalNameActive";
      } else if (this.sortBy === "vehicleInternalNameActive") {
        this.sortBy = "internalNameActive";
      } else if (this.sortBy === "internalNameActive") {
        this.sortBy = "lastNameActive";
      } else {
        this.sortBy = "vehicleInternalNameActive";
      }
      this.sortItems();
    },
    sortItems() {
      switch (this.sortBy) {
        case "id":
          this.items.sort((a, b) => a.id - b.id);
          this.sortIcon = "mdi-identifier";
          break;
        case "lastName":
          this.sortIcon = "mdi-account-details-outline";
          this.items.sort((a, b) => {
            var textA = a.lastName.toUpperCase();
            var textB = b.lastName.toUpperCase();
            if (textA < textB) {
              return -1;
            }
            if (textA > textB) {
              return 1;
            }
            return 0;
          });
          break;
        case "lastNameActive":
          //drivers workStatus === "ONLINE" or "ON_BREAK" will be first sort by lastName, then drivers workStatus === "OFFLINE" will be sort by lastName
          this.sortIcon = "mdi-account-star-outline";
          this.items.sort((a, b) => {
            // Porovnanie podľa "workStatus"
            if (
              (a.workStatus === "ONLINE" || a.workStatus === "ON_BREAK") &&
              !(b.workStatus === "ONLINE" || b.workStatus === "ON_BREAK")
            ) {
              return -1;
            } else if (
              !(a.workStatus === "ONLINE" || a.workStatus === "ON_BREAK") &&
              (b.workStatus === "ONLINE" || b.workStatus === "ON_BREAK")
            ) {
              return 1;
            }

            // Ak majú rovnaký "workStatus", porovnaj podľa "lastName" vzostupne
            const compareLastName =
              a.lastName.toUpperCase() > b.lastName.toUpperCase() ? 1 : -1;

            return compareLastName;
          });
          break;
        case "internalName":
          this.sortIcon = "mdi-card-account-details-outline";
          this.items.sort((a, b) => {
            if (a.internalName && b.internalName) {
              var textA = a.internalName.toUpperCase();
              var textB = b.internalName.toUpperCase();
              if (textA < textB) {
                return -1;
              }
              if (textA > textB) {
                return 1;
              }
              return 0;
            } else if (!a.internalName && b.internalName) {
              return 1;
            } else if (a.internalName && !b.internalName) {
              return -1;
            } else {
              return 0;
            }
          });
          break;
        case "internalNameActive":
          //drivers workStatus !== "OFFLINE" will be first sort by internalName, then drivers workStatus === "OFFLINE" will be sort by internalName
          this.sortIcon = "mdi-card-account-details-star-outline";
          this.items.sort((a, b) => {
            // Porovnanie podľa "workStatus"
            if (
              (a.workStatus === "ONLINE" || a.workStatus === "ON_BREAK") &&
              !(b.workStatus === "ONLINE" || b.workStatus === "ON_BREAK")
            ) {
              return -1;
            } else if (
              !(a.workStatus === "ONLINE" || a.workStatus === "ON_BREAK") &&
              (b.workStatus === "ONLINE" || b.workStatus === "ON_BREAK")
            ) {
              return 1;
            }

            // Ak majú rovnaký "workStatus", porovnaj podľa "internalName" vzostupne
            if (a.internalName && b.internalName) {
              var textA = a.internalName.toUpperCase();
              var textB = b.internalName.toUpperCase();
              if (textA < textB) {
                return -1;
              }
              if (textA > textB) {
                return 1;
              }
              return 0;
            } else if (!a.internalName && b.internalName) {
              return 1;
            } else if (a.internalName && !b.internalName) {
              return -1;
            } else {
              return 0;
            }
          });
          break;
        case "vehicleInternalName":
          this.sortIcon = "mdi-car";
          //niektore vozidla nemaju vehicleInternalName, preto sa zaradia na koniec a usporiadajú podľa internalName vozidla
          //tie co majú vehicleInternalName zoradit podla vehicleInternalName vzostupne na zaciatku zoznamu
          this.items.sort((a, b) => {
            if (a.vehicleInternalName && b.vehicleInternalName) {
              var textA = a.vehicleInternalName.toUpperCase();
              var textB = b.vehicleInternalName.toUpperCase();
              if (textA < textB) {
                return -1;
              }
              if (textA > textB) {
                return 1;
              }
              return 0;
            } else if (!a.vehicleInternalName && b.vehicleInternalName) {
              return 1;
            } else if (a.vehicleInternalName && !b.vehicleInternalName) {
              return -1;
            } else if (a.internalName && b.internalName) {
              var textA = a.internalName.toUpperCase();
              var textB = b.internalName.toUpperCase();
              if (textA < textB) {
                return -1;
              }
              if (textA > textB) {
                return 1;
              }
              return 0;
            } else {
              return 0;
            }
          });
          break;
        case "vehicleInternalNameActive":
          //drivers workStatus !== "OFFLINE" will be first sort by vehicleInternalName, then drivers workStatus === "OFFLINE" will be sort by vehicleInternalName
          this.sortIcon = "mdi-car-select";
          //niektore vozidla nemaju vehicleInternalName, preto sa zaradia na koniec a usporiadajú podľa internalName vozidla
          //tie co majú vehicleInternalName zoradit podla vehicleInternalName vzostupne na zaciatku zoznamu
          this.items.sort((a, b) => {
            // Porovnanie podľa "workStatus"
            if (
              (a.workStatus === "ONLINE" || a.workStatus === "ON_BREAK") &&
              !(b.workStatus === "ONLINE" || b.workStatus === "ON_BREAK")
            ) {
              return -1;
            } else if (
              !(a.workStatus === "ONLINE" || a.workStatus === "ON_BREAK") &&
              (b.workStatus === "ONLINE" || b.workStatus === "ON_BREAK")
            ) {
              return 1;
            }

            // Ak majú rovnaký "workStatus", porovnaj podľa "vehicleInternalName" vzostupne
            if (a.vehicleInternalName && b.vehicleInternalName) {
              var textA = a.vehicleInternalName.toUpperCase();
              var textB = b.vehicleInternalName.toUpperCase();
              if (textA < textB) {
                return -1;
              }
              if (textA > textB) {
                return 1;
              }
              return 0;
            } else if (!a.vehicleInternalName && b.vehicleInternalName) {
              return 1;
            } else if (a.vehicleInternalName && !b.vehicleInternalName) {
              return -1;
            } else if (a.internalName && b.internalName) {
              var textA = a.internalName.toUpperCase();
              var textB = b.internalName.toUpperCase();
              if (textA < textB) {
                return -1;
              }
              if (textA > textB) {
                return 1;
              }
              return 0;
            } else {
              return 0;
            }
          });
          break;
        default:
          this.items.sort((a, b) => a.id - b.id);
      }
    },

    clearEntries() {
      this.items = [];
    },

    fetchEntriesDebounced() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchDriverApi();
      }, 800); /* 500ms throttle */
    },

    /*  hideExtraInput() {
      if (this.value.length > 0) {
        this.$refs.selectComponent.$el.querySelector(
          ".v-select__selections input"
        ).type = "hidden";
      }
    },*/

    /*  getItemText(items) {
      console.log("srč", `${items.firstName}`);
      return `${items.firstName} ${items.lastName}`;
    }, */
  },

  beforeDestroy() {
    clearInterval(this.debounce);
  },
};
</script>

<style>
/*.v-select.v-input--is-dirty input {
  display: none;
}

.v-select__selection {
  max-width: none;
}*/
/*.v-select__selections input {
  width: 0;
}*/
</style>

