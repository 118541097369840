<template>
  <v-container class="pa-1" style="max-height: 85vh">
    <v-row no-gutters>
      <v-col cols="12">
        <!-- <v-card max-width="450" class="mx-auto"> -->
        <v-container>
          <v-form ref="formNewOrder">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  :label="$t('ConsoleOrderNew.phone')"
                  v-model="clientByPhoneSearchData.phone"
                  ref="phoneField"
                  dense
                  clearable
                  @click:clear="clearClientData()"
                  validate-on-blur
                  required
                  :rules="[
                    (v) => !!v || $t('msgRequiredField'),
                    (v) =>
                      /^[+]*\d+$/.test(v) || $t('ConsoleOrderNew.phoneRules'), // 'Phone number has to be numbers only with + prefix',
                  ]"
                  @keydown.enter="getClientByPhone()"
                  prepend-icon="mdi-phone"
                  @click:prepend="
                    getCookieToPhoneHistory(),
                      addRandomNumberToPhoneHistory(),
                      (showPhoneHistoryDialog = true)
                  "
                  append-outer-icon="mdi-magnify"
                  @click:append-outer="getClientByPhone()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  class="ml-8"
                  v-model="clientByExtensionSearchData.extension"
                  :items="extensionList"
                  :label="$t('ConsoleOrderNew.extension')"
                  dense
                  @change="setExtension(clientByExtensionSearchData.extension)"
                  append-outer-icon="mdi-phone-in-talk"
                  @click:append-outer="getClientByExtension('selected')"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="extensionExpand" dense class="py-0 my-0" no-gutters>
              <v-col cols="12" class="py-0 my-0">
                <template v-for="(extension, index) in extensionList">
                  <v-chip
                    :key="extension"
                    class="mr-1 py-0 my-0"
                    @click="getClientByExtension(extension)"
                    :color="getExtensionColor(index)"
                    dark
                    :small="extensionList.length > 7 ? true : false"
                  >
                    {{ extension }}
                  </v-chip>
                </template>
              </v-col>
            </v-row>
            <v-row dense class="py-0 my-0">
              <v-col cols="12" md="6" class="py-0 my-0">
                <v-text-field
                  :label="$t('ConsoleOrderNew.nickname')"
                  :title="$t('ConsoleOrderNew.nickname')"
                  dense
                  readonly
                  single-line
                  v-model="clientData.nickname"
                  prepend-icon="mdi-account-outline"
                  color="grey"
                  hide-details
                  tabindex="-1"
                ></v-text-field>
              </v-col>
              <v-col class="py-0 my-0 d-flex align-items-center">
                <v-btn
                  icon
                  color="info"
                  small
                  :title="$t('ConsoleOrderNew.btnOpenEditClientDialog_title')"
                  :disabled="editClientButton"
                  @click="openEditClientDialog()"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="info"
                  small
                  :title="$t('ConsoleOrderNew.btnOpenHistoryClientDialog')"
                  :disabled="editClientButton"
                  @click="openHistoryClientDialog()"
                >
                  <v-icon> mdi-view-list-outline </v-icon>
                </v-btn>
                <v-btn
                  v-show="fullForm"
                  icon
                  color="info"
                  small
                  :title="$t('ConsoleOrderNew.btnAddClientPhoneToNote')"
                  :disabled="editClientButton"
                  @click="addTextToNote(clientByPhoneSearchData.phone)"
                >
                  <v-icon>mdi-playlist-plus</v-icon>
                </v-btn>
                <v-btn
                  v-show="extensionList.length > 0"
                  icon
                  small
                  @click="extensionExpand = !extensionExpand"
                  :title="
                    extensionExpand
                      ? $t('ConsoleOrderNew.extensionCollapse')
                      : $t('ConsoleOrderNew.extensionExpand')
                  "
                >
                  <v-icon v-if="!extensionExpand"
                    >mdi-phone-settings-outline</v-icon
                  >
                  <v-icon v-if="extensionExpand" color="success"
                    >mdi-phone-settings-outline</v-icon
                  >
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="presetRandomPhone = !presetRandomPhone"
                  :title="
                    presetRandomPhone
                      ? $t('ConsoleOrderNew.presetRandomOff')
                      : $t('ConsoleOrderNew.presetRandomOn')
                  "
                >
                  <v-icon v-if="!presetRandomPhone">mdi-counter</v-icon>
                  <v-icon v-if="presetRandomPhone" color="warning"
                    >mdi-counter</v-icon
                  >
                </v-btn>
                <gb-flag
                  :code="clientCountry.iso2"
                  size="small"
                  v-if="clientCountry != null"
                  :title="clientCountry.name"
                />
              </v-col>
            </v-row>
            <v-row dense class="py-0 my-0">
              <v-col cols="12" class="py-0 my-0">
                <v-text-field
                  class="py-0 my-0"
                  :label="$t('ConsoleOrderNew.clientInfo')"
                  :title="$t('ConsoleOrderNew.clientInfo')"
                  dense
                  readonly
                  single-line
                  v-model="clientData.info"
                  prepend-icon="mdi-information-outline"
                  @click:prepend="openInfoClientDialog()"
                  color="grey"
                  hide-details
                  tabindex="-1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="py-0 my-0">
              <v-col cols="12" class="py-0 my-0">
                <v-text-field
                  class="py-0 my-0"
                  :label="$t('ConsoleOrderNew.clientNote')"
                  :title="$t('ConsoleOrderNew.clientNote')"
                  dense
                  :disabled="editClientButton"
                  single-line
                  v-model="clientData.note"
                  prepend-icon="mdi-note-outline"
                  color="grey"
                  hide-details
                  tabindex="-1"
                  append-outer-icon="mdi-content-save-edit-outline"
                  @click:append-outer="updateClientNote(clientData.note)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <!-- red icon if addressSearchData.countryCode is not null -->
                <v-select
                  :title="$t('ConsoleOrderNew.countryCode_title')"
                  :label="$t('ConsoleOrderNew.countryCode')"
                  v-model="addressSearchData.countryCode"
                  :items="$t('CountryCodeConsoleShortList')"
                  dense
                  hide-details
                  ><template v-slot:prepend>
                    <v-icon
                      :color="
                        addressSearchData.countryCode || addressSearchData.city
                          ? 'red'
                          : 'gray'
                      "
                      @click="clearAddressSearchData()"
                      :title="$t('ConsoleOrderNew.countryCode_prepend_title')"
                    >
                      mdi-map</v-icon
                    >
                  </template></v-select
                >
              </v-col>
              <v-col cols="12" md="6">
                <autocomplete-city-remote
                  v-model="addressSearchData.city"
                  :title="$t('ConsoleOrderNew.city_title')"
                  :label="$t('ConsoleOrderNew.city')"
                  :searchParams="addressSearchData"
                  :hideDetails="true"
                >
                </autocomplete-city-remote>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <component
                  :is="selectedAddressComponent"
                  v-model="boardAddressModel"
                  :label="$t('ConsoleOrderNew.boardAddress')"
                  prepend-icon="fas fa-street-view"
                  :searchParams="addressSearchData"
                  :addAddress="addBoardAddress"
                  :addDefaultAddresses="clientData.addressList"
                  :mapBounds="addressMapBounds"
                  ref="boardAddressSelect"
                  :setFocus="setFocusToBoardAddress"
                  prependIconColor="info"
                  :hideDetails="true"
                >
                </component>
              </v-col>
            </v-row>
            <v-row dense class="py-0 my-0">
              <v-col cols="12" class="py-0 my-0">
                <v-expansion-panels v-model="transitPanelExpansion" hover flat>
                  <v-expansion-panel @click="setFocusToTransit1AddressField()">
                    <v-expansion-panel-header class="justify-self-start pa-0">
                      <div>
                        <v-icon left>mdi-transit-connection-variant</v-icon>
                        <span>{{
                          $t("ConsoleOrderNew.transitAddresses")
                        }}</span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content eager>
                      <v-row>
                        <v-col cols="12">
                          <component
                            :is="selectedAddressComponent"
                            v-model="transit1AddressModel"
                            :label="$t('ConsoleOrderNew.transit1Address')"
                            prepend-icon="mdi-transit-connection-variant"
                            dense
                            :searchParams="addressSearchData"
                            :addDefaultAddresses="clientData.addressList"
                            :mapBounds="addressMapBounds"
                            :setFocus="setFocusToTransit1Address"
                            :hideDetails="true"
                          >
                          </component>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <component
                            :is="selectedAddressComponent"
                            v-model="transit2AddressModel"
                            :label="$t('ConsoleOrderNew.transit2Address')"
                            prepend-icon="mdi-transit-connection-variant"
                            dense
                            class="py-0"
                            :searchParams="addressSearchData"
                            :addDefaultAddresses="clientData.addressList"
                            :mapBounds="addressMapBounds"
                            :hideDetails="true"
                          >
                          </component>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <component
                            :is="selectedAddressComponent"
                            v-model="transit3AddressModel"
                            :label="$t('ConsoleOrderNew.transit3Address')"
                            prepend-icon="mdi-transit-connection-variant"
                            dense
                            class="py-0"
                            :searchParams="addressSearchData"
                            :addDefaultAddresses="clientData.addressList"
                            :mapBounds="addressMapBounds"
                            :hideDetails="true"
                          >
                          </component>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <component
                            :is="selectedAddressComponent"
                            v-model="transit4AddressModel"
                            :label="$t('ConsoleOrderNew.transit4Address')"
                            prepend-icon="mdi-transit-connection-variant"
                            dense
                            class="py-0"
                            :searchParams="addressSearchData"
                            :addDefaultAddresses="clientData.addressList"
                            :mapBounds="addressMapBounds"
                            :hideDetails="true"
                          >
                          </component>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-divider class="m-0"></v-divider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <component
                  :is="selectedAddressComponent"
                  v-model="destAddressModel"
                  :label="$t('ConsoleOrderNew.destinationAddress')"
                  prepend-icon="fas fa-flag-checkered"
                  :searchParams="addressSearchData"
                  :addDefaultAddresses="clientData.addressList"
                  :mapBounds="addressMapBounds"
                  prependIconColor="warning"
                  :hideDetails="true"
                >
                </component>
              </v-col>
            </v-row>
            <div v-show="fullForm">
              <v-row no-gutters class="pt-1 d-flex">
                <v-col cols="12" md="6">
                  <v-radio-group
                    v-model="orderCreateData.type"
                    dense
                    row
                    class="pa-0 ma-0"
                    @change="setRequestedTime()"
                    hide-details
                  >
                    <v-radio
                      :label="$t('ConsoleOrderNew.radioBtnInstant')"
                      value="INSTANT"
                    ></v-radio>
                    <v-radio
                      :label="$t('ConsoleOrderNew.radioBtnTime')"
                      value="TIME"
                      :disabled="!module.timeOrder"
                      hide-details
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="6" class="d-flex">
                  <my-datepicker
                    :label="$t('ConsoleOrderNew.requestedTime')"
                    dense
                    v-model="orderCreateData.requestedTime"
                    :disabled="isDisabledRequestedTime"
                    :hideDetails="true"
                  >
                  </my-datepicker>
                  <!-- button to add time to note -->
                  <v-btn
                    icon
                    :disabled="isDisabledRequestedTime"
                    @click="addRequestedTimeToNote()"
                    class="ma-0"
                    :title="$t('ConsoleOrderNew.btnAddRequestedTimeToNote')"
                  >
                    <v-icon>mdi-playlist-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense no-gutters>
                <v-col cols="6" md="3">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.presendTime')"
                    v-model="orderCreateData.presendTime"
                    :rules="rulesPresendTime"
                    dense
                    type="number"
                    :disabled="isDisabledRequestedTime"
                    suffix="min"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                  <!-- chips to add value 5, 8, 10 to orderCreatedData.presendTime -->
                  <v-chip
                    v-for="n in [5, 8, 10, 15, 30]"
                    :key="n"
                    :title="
                      $t('ConsoleOrderNew.presendTimeChip_title') +
                      ' (' +
                      n +
                      ' min.)'
                    "
                    class="ml-1 mr-0 px-1"
                    x-small
                    pill
                    label
                    :disabled="isDisabledRequestedTime"
                    @click="orderCreateData.presendTime = n"
                    >{{ n }}</v-chip
                  >
                </v-col>
                <v-col cols="12" md="6">
                  <autocomplete-driver-remote
                    v-model="orderCreateData.preferredDriverId"
                    :label="$t('ConsoleOrderNew.preferredDriverId')"
                    :title="$t('ConsoleOrderNew.preferredDriverId_title')"
                    dense
                    hideDetails
                    :singleLine="true"
                    class="pa-0 ma-0"
                    style="max-width: 100%"
                    :searchParams="driverSearchData"
                  >
                  </autocomplete-driver-remote>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-radio-group
                    v-model="orderCreateData.customerType"
                    dense
                    row
                    class="pa-0 ma-0"
                    @change="setAccountId()"
                  >
                    <v-radio
                      :label="$t('ConsoleOrderNew.radioBtnClient')"
                      value="CLIENT"
                    ></v-radio>
                    <v-radio
                      :label="$t('ConsoleOrderNew.radioBtnAccount')"
                      v-show="!isDisabledAccountPaymentOption"
                      value="ACCOUNT"
                      :disabled="!module.account"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                  <autocomplete-account-remote
                    v-model="orderCreateData.accountId"
                    :label="$t('ConsoleOrderNew.accountId')"
                    :disabled="isDisabledCustomerType"
                    v-show="!isDisabledCustomerType"
                    dense
                    class="pa-0 ma-0"
                    :searchParams="accountSearchData"
                    :hideDetails="true"
                  >
                  </autocomplete-account-remote>
                  <!-- :items="paymentTypeItems" -->
                  <!-- :items="$t('paymentTypeArray')" -->
                  <v-select
                    v-model="orderCreateData.paymentType"
                    :items="paymentTypeItems"
                    :label="$t('ConsoleOrderNew.paymentType')"
                    v-show="isDisabledCustomerType"
                    class="pa-0 ma-0"
                    prepend-icon="mdi-cash"
                    @change="setPaymentOption()"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense class="py-0 my-0">
                <v-col cols="12" class="py-0 my-0">
                  <v-expansion-panels hover flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="justify-self-start pa-0">
                        <div>
                          <v-icon left>mdi-cog-box</v-icon>
                          <span
                            >{{ $t("ConsoleOrderNew.orderFilterHeader")
                            }}{{
                              (orderFilter.animal != "ANY"
                                ? " | " + $t("orderFilter.animal")
                                : "") +
                              /*(orderFilter.autonomous != "ANY"
                          ? " | " + $t("orderFilter.autonomous")
                          : "") +*/
                              (orderFilter.childrenChair != "ANY"
                                ? " | " + $t("orderFilter.childrenChair")
                                : "") +
                              (orderFilter.luggage != "ANY"
                                ? " | " + $t("orderFilter.luggage")
                                : "") +
                              /*(orderFilter.eco != "ANY"
                          ? " | " + $t("orderFilter.eco")
                          : "") +*/
                              (orderFilter.maxPerson != 0
                                ? " | " + $t("orderFilter.maxPerson")
                                : "") +
                              /*(orderFilter.smoking != "ANY"
                          ? " | " + $t("orderFilter.smoking")
                          : "") +*/
                              (orderFilter.specialTransport != "ANY"
                                ? " | " + $t("orderFilter.specialTransport")
                                : "") +
                              (orderFilter.paymentTerminal != "ANY"
                                ? " | " + $t("orderFilter.paymentTerminal")
                                : "")
                            }}{{
                              orderCreateData.onlyManualSending == true
                                ? " | \u26A0 "
                                : " "
                            }}</span
                          >
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row dense>
                          <v-col cols="12">
                            <v-select
                              :label="$t('ConsoleOrderNew.onlyManualSending')"
                              dense
                              v-model="orderCreateData.onlyManualSending"
                              :items="$t('onlyManualSendingArray')"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--<v-col cols="12" md="6">
                            <v-select
                              :label="$t('ConsoleOrderNew.orderFilter.airconditioning')"
                              dense
                              v-model="orderFilter.airconditioning"
                              :items="$t('orderFilterSelect.airconditioning')"
                              :rules="[
                                (v) => !!v || $t('msgRequiredField'),
                              ]"
                              required
                            ></v-select>
                          </v-col>-->
                          <v-col cols="12" md="6">
                            <v-select
                              :label="$t('ConsoleOrderNew.orderFilter.animal')"
                              dense
                              v-model="orderFilter.animal"
                              :items="$t('orderFilterSelect.animal')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              :label="$t('ConsoleOrderNew.orderFilter.luggage')"
                              dense
                              v-model="orderFilter.luggage"
                              :items="$t('orderFilterSelect.luggage')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--<v-col cols="12" md="6">
                            <v-select
                              :label="$t('ConsoleOrderNew.orderFilter.autonomous')"
                              dense
                              v-model="orderFilter.autonomous"
                              :items="$t('orderFilterSelect.autonomous')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>-->
                          <v-col cols="12" md="6">
                            <v-select
                              :label="
                                $t(
                                  'ConsoleOrderNew.orderFilter.paymentTerminal'
                                )
                              "
                              dense
                              v-model="orderFilter.paymentTerminal"
                              :items="$t('orderFilterSelect.paymentTerminal')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              :label="
                                $t('ConsoleOrderNew.orderFilter.childrenChair')
                              "
                              dense
                              v-model="orderFilter.childrenChair"
                              :items="$t('orderFilterSelect.childrenChair')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                        <!--<v-row dense>
                          <v-col cols="12" md="6">
                            <v-select
                              :label="$t('ConsoleOrderNew.orderFilter.driverRating')"
                              dense
                              v-model="orderFilter.driverRating"
                              :items="$t('orderFilterSelect.driverRating')"
                              :rules="[
                                (v) => !!v || $t('msgRequiredField'),
                              ]"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                             :label="$t('ConsoleOrderNew.orderFilter.eco')"
                              dense
                              v-model="orderFilter.eco"
                              :items="$t('orderFilterSelect.eco')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>-->
                        <v-row dense>
                          <v-col cols="12" md="6">
                            <v-select
                              :label="
                                $t('ConsoleOrderNew.orderFilter.maxPerson')
                              "
                              dense
                              v-model="orderFilter.maxPerson"
                              :items="$t('orderFilterSelect.maxPerson')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                          <!--<v-col cols="12" md="6">
                            <v-select
                              :label="$t('ConsoleOrderNew.orderFilter.smoking')"
                              dense
                              v-model="orderFilter.smoking"
                              :items="$t('orderFilterSelect.smoking')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row dense>-->
                          <v-col cols="12" md="6">
                            <v-select
                              :label="
                                $t(
                                  'ConsoleOrderNew.orderFilter.specialTransport'
                                )
                              "
                              dense
                              v-model="orderFilter.specialTransport"
                              :items="$t('orderFilterSelect.specialTransport')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.note')"
                    dense
                    v-model="orderCreateData.note"
                    :rules="rulesNote"
                    counter
                    clearable
                    @click:clear="orderCreateData.note = ''"
                    maxlength="80"
                    prepend-icon="mdi-playlist-edit"
                    @click:prepend="addOrderDataNote()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-row dense>
              <v-col cols="12" md="9">
                <v-btn
                  v-show="!fullForm"
                  color="success"
                  dense
                  medium
                  @click="
                    openCreateOrderDialog(), (createOrderDialogNextShow = true)
                  "
                  class="ma-1"
                >
                  <v-icon left dense dark> fas fa-edit </v-icon>
                  {{ $t("ConsoleOrderNew.btnCreate") }}
                </v-btn>
                <v-btn
                  v-show="fullForm"
                  color="success"
                  dense
                  medium
                  @click="createOrder(), (createOrderDialogNextShow = false)"
                  class="ma-1"
                  :loading="loadingCreateOrderButton"
                  :disabled="loadingCreateOrderButton"
                >
                  <v-icon left dense dark> fas fa-edit </v-icon>
                  {{ $t("ConsoleOrderNew.btnCreate") }}
                </v-btn>
                <!--<v-btn
                  v-show="!fullForm"
                  color="warning"
                  dense
                  medium
                  @click.stop="createOrder()"
                  class="ma-1"
                >
                  <v-icon left dense dark> mdi-play-speed </v-icon>
                  Rýchla
                </v-btn>-->
                <v-btn
                  color="error"
                  dense
                  medium
                  @click="clearOrderData()"
                  class="ma-1"
                >
                  <v-icon left dense dark> mdi-backspace-outline </v-icon>
                  {{ $t("ConsoleOrderNew.btnClear") }}
                </v-btn>
                <v-btn
                  v-show="fullForm && orderCreateData.type == 'TIME'"
                  color="warning"
                  icon
                  dense
                  medium
                  @click="openCreateOrderMultiTimeDialog()"
                  class="ma-1"
                >
                  <v-icon dense dark> mdi-calendar </v-icon>
                </v-btn> </v-col
              ><v-col cols="12" md="3">
                <v-checkbox
                  v-model="fullForm"
                  label="Rozšírenie"
                  title="Rozšíriť formulár o ďalšie možnosti z vyskakovacieho okna a následne nezobrazovať vyskakovacie okno"
                  dense
                  @change="saveFullFormToCookie()"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-col>
    </v-row>
    <!-- Dialog na Default nastaveni pri vytvarani pouzivatela-->
    <v-dialog
      v-model="defaultNewClientDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderNew.defaultNewClientDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row dense class="py-0 my-0">
              <v-col cols="12" class="py-0 my-0">
                <v-text-field
                  :label="$t('ConsoleOrderNew.nickname')"
                  filled
                  dense
                  v-model="nicknameDefault"
                  required
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" class="py-0 my-0">
                <v-select
                  :label="$t('ConsoleOrderNew.notification')"
                  filled
                  dense
                  v-model="notificationDefault"
                  :items="$t('clientNotificationSelect')"
                  required
                ></v-select>
              </v-col> -->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.stop="defaultNewClientDialog = false"
            >{{ $t("btnClose") }}</v-btn
          >
          <v-btn
            color="success"
            text
            @click.stop="
              setDefaultNewClient(), (defaultNewClientDialog = false)
            "
            >{{ $t("btnSetDefault") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na Vytvorenie pouzivatela-->
    <v-dialog
      v-model="createClientDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderNew.createdClientDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="createClientForm">
          <v-card-text>
            <v-container>
              <v-row dense class="py-0 my-0">
                <v-col cols="12" class="py-0 my-0">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.phone')"
                    ref="createClientPhone"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="clientCreateData.phone"
                    dense
                    required
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0 my-0">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.nickname')"
                    filled
                    dense
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="clientCreateData.nickname"
                    required
                    append-outer-icon="mdi-cog"
                    @click:append-outer="defaultNewClientDialog = true"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0 my-0">
                  <v-select
                    :label="$t('ConsoleOrderNew.mark')"
                    filled
                    dense
                    v-model="clientCreateData.mark"
                    :items="$t('clientMarkSelect')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0 my-0">
                  <v-select
                    :label="$t('ConsoleOrderNew.notification')"
                    filled
                    dense
                    v-model="clientCreateData.notification"
                    :items="$t('clientNotificationSelect')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-alert
                v-model="phoneCountryAlert"
                type="warning"
                dense
                dismissible
              >
                {{ $t("ConsoleOrderNew.msgPhoneCrountryAlert1") }}
                {{ phoneCountryName
                }}{{ $t("ConsoleOrderNew.msgPhoneCrountryAlert2") }}
              </v-alert>
              <v-alert
                v-model="phoneLandlineAlert"
                type="warning"
                dense
                dismissible
              >
                {{ $t("ConsoleOrderNew.msgPhoneLandlineAlert") }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click.stop="createClientDialog = false"
              >{{ $t("btnClose") }}</v-btn
            >
            <v-btn
              color="success"
              text
              @click.stop="createClient()"
              :disabled="loadingCreateClientButton"
              >{{ $t("btnCreate") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog na upravu pouzivatela-->
    <v-dialog
      v-model="editClientDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderNew.editClientDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="updateClientForm">
          <v-card-text>
            <v-container>
              <v-row dense class="py-0 my-0">
                <v-col cols="12" class="py-0 my-0">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.phone')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="clientUpdateData.phone"
                    dense
                    required
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0 my-0">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.nickname')"
                    filled
                    dense
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="clientUpdateData.nickname"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0 my-0">
                  <v-select
                    :label="$t('ConsoleOrderNew.mark')"
                    filled
                    dense
                    v-model="clientUpdateData.mark"
                    :items="$t('clientMarkSelect')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0 my-0">
                  <v-select
                    :label="$t('ConsoleOrderNew.notification')"
                    filled
                    dense
                    v-model="clientUpdateData.notification"
                    :items="$t('clientNotificationSelect')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0 my-0">
                  <v-checkbox
                    :label="$t('ConsoleOrderNew.virtualAssistant')"
                    filled
                    dense
                    v-model="clientUpdateData.virtualAssistant"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" class="py-0 my-0">
                  <autocomplete-account-remote
                    v-model="clientUpdateData.accountId"
                    :label="$t('ConsoleOrderNew.accountId')"
                    :disabled="disabledAccountChange"
                    dense
                    class="pa-0 ma-0"
                    :searchParams="accountSearchData"
                  >
                  </autocomplete-account-remote
                ></v-col>
                <v-col cols="12" class="py-0 my-0">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.clientNote')"
                    filled
                    dense
                    v-model="clientUpdateData.note"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="editClientDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="updateClient()">{{
              $t("btnUpdate")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog s informaciami o pouzivatelovi-->
    <v-dialog
      v-model="infoClientDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderNew.infoClientDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="infoClientForm">
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.phone')"
                    v-model="clientData.phone"
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.nickname')"
                    v-model="clientData.nickname"
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('ConsoleOrderNew.mark')"
                    v-model="clientData.mark"
                    dense
                    :items="$t('clientMarkSelect')"
                    readonly
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('ConsoleOrderNew.notification')"
                    v-model="clientData.notification"
                    dense
                    :items="$t('clientNotificationSelect')"
                    readonly
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0 my-0">
                  <v-checkbox
                    :label="$t('ConsoleOrderNew.virtualAssistant')"
                    filled
                    dense
                    v-model="clientData.virtualAssistant"
                    readonly
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.clientNote')"
                    v-model="clientData.note"
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <!-- this.clientData.rating -->
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.rating')"
                    v-model="clientData.rating"
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <!-- this.clientData.driveCounter -->
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.driveCounter')"
                    v-model="clientData.driveCounter"
                    dense
                    readonly
                  ></v-text-field
                ></v-col>
              </v-row>
              <!-- create simple table (loop this.clientData.acccountList) with rows name, status -->
              <v-row dense v-if="clientData.accountList">
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="pl-0">{{
                    $t("ConsoleOrderNew.accounts")
                  }}</v-subheader>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("ConsoleOrderNew.accountName") }}
                          </th>
                          <th class="text-left">
                            {{ $t("ConsoleOrderNew.accountClientStatus") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="account in clientData.accountList"
                          :key="account.id"
                        >
                          <td>{{ account.name }}</td>
                          <td>
                            {{
                              $t("AccountEnums.ClientStatus." + account.status)
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="infoClientDialog = false">{{
              $t("btnClose")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog so zoznamom objednavok pouzivatela-->
    <v-dialog
      v-model="historyClientDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderNew.historyClientDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in clientHistoryData"
                :key="i"
                @click="showOrderDetail(item.orderId)"
              >
                <v-list-item-icon>
                  <v-icon :color="getStatusColor(item.status)"
                    >mdi-information</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.createdAt | formatDateShort }}
                    {{ " | " + $t("orderStatus." + item.status) }}
                    {{ " | " + $t("orderType." + item.type) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.orderId.slice(0, 8)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click.stop="historyClientDialog = false">{{
            $t("btnClose")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na Vytvorenie novej objednavky-->
    <v-dialog
      v-model="createOrderDialog"
      transition="dialog-top-transition"
      max-width="600px"
      @click:outside="
        (createOrderDialog = false), (createOrderDialogNextShow = false)
      "
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderNew.createOrderDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="createOrderForm">
          <v-card-text>
            <v-container>
              <v-row no-gutters v-show="displayOnlyForAdmin">
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Client ID"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="clientData.id"
                    readonly
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.phone')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="clientData.phone"
                    prepend-icon="mdi-phone-outline"
                    @click:prepend="addTextToNote(clientData.phone)"
                    dense
                    readonly
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.nickname')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="clientData.nickname"
                    prepend-icon="mdi-badge-account-horizontal-outline"
                    @click:prepend="addTextToNote(clientData.nickname)"
                    dense
                    readonly
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.boardAddress')"
                    v-model="boardAddressModel.displayName"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    dense
                    hide-details
                    disabled
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-expansion-panels flat v-model="dialogTransitAddressPanel">
                    <v-expansion-panel
                      expand
                      v-model="dialogTransitAddressPanel"
                    >
                      <v-expansion-panel-header>
                        {{ $t("ConsoleOrderNew.transitAddresses") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model="transit1AddressModel.displayName"
                              :label="$t('ConsoleOrderNew.transit1Address')"
                              dense
                              disabled
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model="transit2AddressModel.displayName"
                              :label="$t('ConsoleOrderNew.transit2Address')"
                              dense
                              disabled
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model="transit3AddressModel.displayName"
                              :label="$t('ConsoleOrderNew.transit3Address')"
                              dense
                              disabled
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model="transit4AddressModel.displayName"
                              :label="$t('ConsoleOrderNew.transit4Address')"
                              dense
                              disabled
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.destinationAddress')"
                    v-model="destAddressModel.displayName"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    class="pt-2"
                    dense
                    disabled
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-radio-group
                    v-model="orderCreateData.type"
                    dense
                    row
                    class="pa-0 ma-0"
                    @change="setRequestedTime()"
                  >
                    <v-radio
                      :label="$t('ConsoleOrderNew.radioBtnInstant')"
                      value="INSTANT"
                    ></v-radio>
                    <v-radio
                      :label="$t('ConsoleOrderNew.radioBtnTime')"
                      value="TIME"
                      :disabled="!module.timeOrder"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                  <my-datepicker
                    :label="$t('ConsoleOrderNew.requestedTime')"
                    dense
                    v-model="orderCreateData.requestedTime"
                    :disabled="isDisabledRequestedTime"
                  >
                  </my-datepicker>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6" md="3">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.presendTime')"
                    v-model="orderCreateData.presendTime"
                    :rules="rulesPresendTime"
                    dense
                    type="number"
                    :disabled="isDisabledRequestedTime"
                  ></v-text-field
                ></v-col>
                <v-col cols="6" md="3">
                  <!-- chips to add value 5, 8, 10 to orderCreatedData.presendTime -->
                  <v-chip
                    v-for="n in [5, 8, 10, 12, 15]"
                    :key="n"
                    :title="
                      $t('ConsoleOrderNew.presendTimeChip_title') +
                      ' (' +
                      n +
                      ' min.)'
                    "
                    class="ml-1 mr-0 px-1"
                    small
                    pill
                    label
                    :disabled="isDisabledRequestedTime"
                    @click="orderCreateData.presendTime = n"
                    >{{ n }}</v-chip
                  >
                </v-col>
                <v-col cols="12" md="6">
                  <autocomplete-driver-remote
                    v-model="orderCreateData.preferredDriverId"
                    :label="$t('ConsoleOrderNew.preferredDriverId')"
                    :title="$t('ConsoleOrderNew.preferredDriverId_title')"
                    dense
                    class="pa-0 ma-0"
                    :searchParams="driverSearchData"
                  >
                  </autocomplete-driver-remote>
                  <!--   <v-text-field
                    label="Preferred Driver"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="orderCreateData.preferredDriverId"
                    filled
                    dense
                  ></v-text-field> -->
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-radio-group
                    v-model="orderCreateData.customerType"
                    dense
                    row
                    class="pa-0 ma-0"
                    @change="setAccountId()"
                  >
                    <v-radio
                      :label="$t('ConsoleOrderNew.radioBtnClient')"
                      value="CLIENT"
                    ></v-radio>
                    <v-radio
                      :label="$t('ConsoleOrderNew.radioBtnAccount')"
                      v-show="!isDisabledAccountPaymentOption"
                      value="ACCOUNT"
                      :disabled="!module.account"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                  <autocomplete-account-remote
                    v-model="orderCreateData.accountId"
                    :label="$t('ConsoleOrderNew.accountId')"
                    :disabled="isDisabledCustomerType"
                    v-show="!isDisabledCustomerType"
                    dense
                    class="pa-0 ma-0"
                    :searchParams="accountSearchData"
                  >
                  </autocomplete-account-remote>
                  <v-select
                    v-model="orderCreateData.paymentType"
                    :items="paymentTypeItems"
                    :label="$t('ConsoleOrderNew.paymentType')"
                    v-show="isDisabledCustomerType"
                    class="pa-0 ma-0"
                    prepend-icon="mdi-cash"
                    @change="setPaymentOption()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        {{ $t("ConsoleOrderNew.orderFilterHeader") }}
                        {{
                          (orderFilter.animal != "ANY"
                            ? " | " + $t("orderFilter.animal")
                            : "") +
                          /*(orderFilter.autonomous != "ANY"
                          ? " | " + $t("orderFilter.autonomous")
                          : "") +*/
                          (orderFilter.childrenChair != "ANY"
                            ? " | " + $t("orderFilter.childrenChair")
                            : "") +
                          (orderFilter.luggage != "ANY"
                            ? " | " + $t("orderFilter.luggage")
                            : "") +
                          /*(orderFilter.eco != "ANY"
                          ? " | " + $t("orderFilter.eco")
                          : "") +*/
                          (orderFilter.maxPerson != 0
                            ? " | " + $t("orderFilter.maxPerson")
                            : "") +
                          /*(orderFilter.smoking != "ANY"
                          ? " | " + $t("orderFilter.smoking")
                          : "") +*/
                          (orderFilter.specialTransport != "ANY"
                            ? " | " + $t("orderFilter.specialTransport")
                            : "") +
                          (orderFilter.paymentTerminal != "ANY"
                            ? " | " + $t("orderFilter.paymentTerminal")
                            : "")
                        }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row dense>
                          <!--<v-col cols="12" md="6">
                            <v-select
                              label="Airconditioning"
                              dense
                              v-model="orderFilter.airconditioning"
                              :items="$t('orderFilterSelect.airconditioning')"
                              :rules="[
                                (v) => !!v || $t('msgRequiredField'),
                              ]"
                              required
                            ></v-select>
                          </v-col>-->
                          <v-col cols="12" md="6">
                            <v-select
                              :label="$t('ConsoleOrderNew.orderFilter.animal')"
                              dense
                              v-model="orderFilter.animal"
                              :items="$t('orderFilterSelect.animal')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              :label="$t('ConsoleOrderNew.orderFilter.luggage')"
                              dense
                              v-model="orderFilter.luggage"
                              :items="$t('orderFilterSelect.luggage')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--<v-col cols="12" md="6">
                            <v-select
                              label="Autonomous"
                              dense
                              v-model="orderFilter.autonomous"
                              :items="$t('orderFilterSelect.autonomous')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select> </v-col>-->
                          <v-col cols="12" md="6">
                            <v-select
                              :label="
                                $t(
                                  'ConsoleOrderNew.orderFilter.paymentTerminal'
                                )
                              "
                              dense
                              v-model="orderFilter.paymentTerminal"
                              :items="$t('orderFilterSelect.paymentTerminal')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              :label="
                                $t('ConsoleOrderNew.orderFilter.childrenChair')
                              "
                              dense
                              v-model="orderFilter.childrenChair"
                              :items="$t('orderFilterSelect.childrenChair')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                        <!-- <v-row dense>
                          <v-col cols="12" md="6">
                            <v-select
                              label="Driver Rating"
                              dense
                              v-model="orderFilter.driverRating"
                              :items="$t('orderFilterSelect.driverRating')"
                              :rules="[
                                (v) => !!v ||$t('msgRequiredField'),
                              ]"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              label="Eco"
                              dense
                              v-model="orderFilter.eco"
                              :items="$t('orderFilterSelect.eco')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>-->
                        <v-row dense>
                          <v-col cols="12" md="6">
                            <v-select
                              :label="
                                $t('ConsoleOrderNew.orderFilter.maxPerson')
                              "
                              dense
                              v-model="orderFilter.maxPerson"
                              :items="$t('orderFilterSelect.maxPerson')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                          <!--<v-col cols="12" md="6">
                            <v-select
                              label="Smoking"
                              dense
                              v-model="orderFilter.smoking"
                              :items="$t('orderFilterSelect.smoking')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row dense>-->
                          <v-col cols="12" md="6">
                            <v-select
                              :label="
                                $t(
                                  'ConsoleOrderNew.orderFilter.specialTransport'
                                )
                              "
                              dense
                              v-model="orderFilter.specialTransport"
                              :items="$t('orderFilterSelect.specialTransport')"
                              :rules="[(v) => !!v || $t('msgRequiredField')]"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.note')"
                    dense
                    v-model="orderCreateData.note"
                    :rules="rulesNote"
                    counter
                    clearable
                    @click:clear="orderCreateData.note = ''"
                    maxlength="80"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              dense
              text
              @click.stop="
                (createOrderDialog = false), (createOrderDialogNextShow = false)
              "
              >{{ $t("btnClose") }}</v-btn
            >
            <v-btn
              color="success"
              dense
              text
              @click.stop="createOrder(), (createOrderDialogNextShow = false)"
              :loading="loadingCreateOrderDialogButton"
              :disabled="loadingCreateOrderDialogButton"
              >{{ $t("ConsoleOrderNew.btnCreate") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog na Vytvorenie novych objednavok s viacerymi casmi-->
    <v-dialog
      v-model="createOrderMultiTimeDialog"
      transition="dialog-top-transition"
      max-width="600px"
      @click:outside="
        (createOrderMultiTimeDialog = false),
          (createOrderDialogNextShow = false)
      "
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderNew.createOrderDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="createOrderMultiForm">
          <v-card-text>
            <v-container>
              <v-row no-gutters v-show="displayOnlyForAdmin">
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Client ID"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="clientData.id"
                    readonly
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.phone')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="clientData.phone"
                    prepend-icon="mdi-phone-outline"
                    @click:prepend="addTextToNote(clientData.phone)"
                    dense
                    readonly
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.nickname')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="clientData.nickname"
                    prepend-icon="mdi-badge-account-horizontal-outline"
                    @click:prepend="addTextToNote(clientData.nickname)"
                    dense
                    readonly
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.boardAddress')"
                    v-model="boardAddressModel.displayName"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    dense
                    hide-details
                    disabled
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-expansion-panels flat v-model="dialogTransitAddressPanel">
                    <v-expansion-panel
                      expand
                      v-model="dialogTransitAddressPanel"
                    >
                      <v-expansion-panel-header>
                        {{ $t("ConsoleOrderNew.transitAddresses") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model="transit1AddressModel.displayName"
                              :label="$t('ConsoleOrderNew.transit1Address')"
                              dense
                              disabled
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model="transit2AddressModel.displayName"
                              :label="$t('ConsoleOrderNew.transit2Address')"
                              dense
                              disabled
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model="transit3AddressModel.displayName"
                              :label="$t('ConsoleOrderNew.transit3Address')"
                              dense
                              disabled
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model="transit4AddressModel.displayName"
                              :label="$t('ConsoleOrderNew.transit4Address')"
                              dense
                              disabled
                              required
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.destinationAddress')"
                    v-model="destAddressModel.displayName"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    class="pt-2"
                    dense
                    disabled
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.note')"
                    dense
                    v-model="orderCreateData.note"
                    :rules="rulesNote"
                    counter
                    clearable
                    @click:clear="orderCreateData.note = ''"
                    maxlength="80"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-dialog
                    ref="dialog"
                    v-model="timePickerDialog"
                    :return-value.sync="requestedHoursMinutes"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="requestedHoursMinutes"
                        :label="$t('ConsoleOrderNew.requestedTime')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="timePickerDialog"
                      v-model="requestedHoursMinutes"
                      full-width
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="timePickerDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialog.save(requestedHoursMinutes),
                            setRequestedMultiTime()
                        "
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    :label="$t('ConsoleOrderNew.presendTime')"
                    v-model="orderCreateData.presendTime"
                    :rules="rulesPresendTime"
                    prepend-icon="mdi-timer-sand"
                    dense
                    type="number"
                    :disabled="isDisabledRequestedTime"
                  ></v-text-field
                ></v-col>
                <v-col cols="6" md="3">
                  <!-- chips to add value 5, 8, 10 to orderCreatedData.presendTime -->
                  <v-chip
                    v-for="n in [5, 8, 10, 12, 15]"
                    :key="n"
                    :title="
                      $t('ConsoleOrderNew.presendTimeChip_title') +
                      ' (' +
                      n +
                      ' min.)'
                    "
                    class="ml-1 mr-0 px-1"
                    small
                    pill
                    label
                    :disabled="isDisabledRequestedTime"
                    @click="orderCreateData.presendTime = n"
                    >{{ n }}</v-chip
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <!-- chip to check all requestedTimeCheck -->
                  <v-chip
                    color="blue"
                    text
                    @click="checkAllRequestedTimeCheck()"
                    class="mb-1 white--text"
                    small
                    ><v-icon left small
                      >mdi-checkbox-multiple-marked-outline</v-icon
                    >{{ $t("ConsoleOrderNew.btnCheckAll") }}</v-chip
                  >

                  <!-- chip to check none requestedTimeCheck -->
                  <v-chip
                    color="red"
                    text
                    @click="checkNoneRequestedTimeCheck()"
                    class="mb-1 ml-1 white--text"
                    small
                    ><v-icon left small
                      >mdi-checkbox-multiple-blank-outline</v-icon
                    >{{ $t("ConsoleOrderNew.btnCheckNone") }}</v-chip
                  >

                  <!-- chip to check reverse requestedTimeCheck -->
                  <v-chip
                    color="orange"
                    text
                    @click="checkReverseRequestedTimeCheck()"
                    class="mb-1 ml-1 white--text"
                    small
                    ><v-icon left small>mdi-format-list-checks</v-icon
                    >{{ $t("ConsoleOrderNew.btnCheckReverse") }}</v-chip
                  >

                  <!-- chip to check weekdays requestedTimeCheck -->
                  <v-chip
                    color="green"
                    text
                    @click="checkWeekdaysRequestedTimeCheck()"
                    class="mb-1 ml-1 white--text"
                    small
                    ><v-icon left small>mdi-calendar-week</v-icon
                    >{{ $t("ConsoleOrderNew.btnCheckWeekdays") }}</v-chip
                  >

                  <!-- chip to check weekend requestedTimeCheck -->
                  <v-chip
                    color="purple"
                    text
                    @click="checkWeekendRequestedTimeCheck()"
                    class="mb-1 ml-1 white--text"
                    small
                    ><v-icon left small>mdi-calendar-week-begin-outline</v-icon
                    >{{ $t("ConsoleOrderNew.btnCheckWeekend") }}</v-chip
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row v-for="i in 7" :key="i">
                    <!-- checkbox requestedTimeCheck, label is weekday -->
                    <v-col cols="6" md="6">
                      <v-checkbox
                        v-model="requestedTimeCheck[i - 1]"
                        :label="$t('weekdayName.' + requestedWeekday[i - 1])"
                        dense
                        @change="setRequestedMultiTime()"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6" md="6">
                      <!-- picker requestedTime -->
                      <v-menu
                        v-model="datePickerDialog[i - 1]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        readonly
                        dense
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="requestedDate[i - 1]"
                            :label="$t('ConsoleOrderNew.requstedDateMulti') + i"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="requestedDate[i - 1]"
                          @input="
                            (datePickerDialog[i - 1] = false),
                              setRequestedMultiTime()
                          "
                          v-if="false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row v-for="i in 7" :key="i + 7">
                    <!-- checkbox requestedTimeCheck, label is weekday -->
                    <v-col cols="6" md="6">
                      <v-checkbox
                        v-model="requestedTimeCheck[i + 6]"
                        :label="$t('weekdayName.' + requestedWeekday[i + 6])"
                        dense
                        @change="setRequestedMultiTime()"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6" md="6">
                      <!-- picker requestedTime -->
                      <v-menu
                        v-model="datePickerDialog[i + 6]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        readonly
                        dense
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="requestedDate[i + 6]"
                            :label="
                              $t('ConsoleOrderNew.requstedDateMulti') + (i + 7)
                            "
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="requestedDate[i + 6]"
                          @input="
                            (datePickerDialog[i + 6] = false),
                              setRequestedMultiTime()
                          "
                          v-if="false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              dense
              text
              @click.stop="
                (createOrderMultiTimeDialog = false),
                  (loadingCreateOrderMultiTimeButton = false)
              "
              >{{ $t("btnClose") }}</v-btn
            >
            <v-btn
              color="success"
              dense
              text
              @click.stop="createOrderMultiTime()"
              :loading="loadingCreateOrderMultiTimeButton"
              :disabled="loadingCreateOrderMultiTimeButton"
              >{{ $t("ConsoleOrderNew.btnCreate") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog o uspesnom vytvoreni novej objednavky-->
    <v-dialog
      v-model="successCreateOrderDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderNew.successCreateOrderDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <p>{{ $t("ConsoleOrderNew.msgOrderCreateSuccess") }}</p>
                <p>
                  {{ $t("ConsoleOrderNew.msgOrderCreateText") }}
                </p>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-switch
                  v-model="autoHideSuccessCreateOrderDialog"
                  :label="
                    $t('ConsoleOrderNew.autoHideSuccessCreateOrderDialogSwitch')
                  "
                  color="success"
                  :disabled="autoHideSuccessCreateOrderClearDialog"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-switch
                  v-model="autoHideSuccessCreateOrderClearDialog"
                  :label="
                    $t(
                      'ConsoleOrderNew.autoHideSuccessCreateOrderClearDialogSwitch'
                    )
                  "
                  color="warning"
                  :disabled="autoHideSuccessCreateOrderDialog"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
          :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.stop="successCreateOrderDialog = false"
            >{{ $t("btnClose") }}</v-btn
          >
          <v-btn
            color="success"
            text
            title="Vytvoriť novú čistú objednávku"
            @click.stop="clearOrderData(), (successCreateOrderDialog = false)"
            >{{ $t("ConsoleOrderNew.btnNewOrder") }}</v-btn
          >
          <!-- Ziskanie udajov o zakazanikovi z prebiehajuceho hovoru podla klapky na ustredni-->
          <v-btn
            color="info"
            text
            title="Vyhľadať zákazníka podľa prebiehajúceho hovoru na ústredni"
            @click="
              clearOrderData(),
                getClientByExtension('selected'),
                (successCreateOrderDialog = false)
            "
          >
            <v-icon> mdi-phone-in-talk </v-icon>
            <!-- <span v-show="!$vuetify.breakpoint.smAndDown">Načítať hovor</span> -->
            <span>{{ $t("ConsoleOrderNew.btnGetClientByExtension") }}</span>
          </v-btn>
          <!-- Detail práve vytvorenej novej objednávky -->
          <v-btn
            color="info"
            text
            icon
            :title="$t('ConsoleOrderNew.btnShowOrderDetail_title')"
            @click="
              (successCreateOrderDialog = false),
                showOrderDetail(createdOrderId),
                clearOrderData()
            "
          >
            <v-icon> mdi-information</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na setAccountIdFromClient - Nastaviť firmu zákazníka -->
    <v-dialog
      v-model="setAccountIdFromClientDialog"
      max-width="600px"
      @click:outside="
        (setAccountIdFromClientDialog = false),
          createOrderDialogNextShow == true
            ? ((createOrderDialog = true), (createOrderDialogNextShow = false))
            : (createOrderDialogNextShow = false)
      "
    >
      <v-card>
        <v-card-title class="headline">
          <v-icon large>mdi-store</v-icon>
          <span class="pl-2">{{
            $t("ConsoleOrderNew.setAccountIdFromClientDialog_title")
          }}</span>
        </v-card-title>
        <v-card-text class="py-2">
          {{ $t("ConsoleOrderNew.msgSetAccountIdText") }}
        </v-card-text>
        <v-col cols="12">
          <v-checkbox
            :label="$t('ConsoleOrderNew.alwaysShowAccountAlertDialog_label')"
            v-model="setAccountIdFromClientDialogEnable"
            :title="$t('ConsoleOrderNew.alwaysShowAccountAlertDialog_title')"
            dense
            filled
            hide-details
          ></v-checkbox>
        </v-col>

        <v-card-actions
          :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.stop="
              (setAccountIdFromClientDialog = false),
                createOrderDialogNextShow == true
                  ? ((createOrderDialog = true),
                    (createOrderDialogNextShow = false))
                  : (createOrderDialogNextShow = false)
            "
            >{{ $t("btnClose") }}</v-btn
          >
          <v-btn color="info" text @click.stop="setClientPaymentTypeConfirm()">
            {{ $t("ConsoleOrderNew.btnPaymentPrivate") }}</v-btn
          >
          <v-btn
            color="success"
            text
            @click.stop="setAccountIdFromClientConfirm()"
          >
            {{ $t("ConsoleOrderNew.btnPaymentAccount") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na showOrderDetail -->
    <v-dialog
      v-model="showOrderDetailDialog"
      @click:outside="setFocusToBoardAddress = true"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="headline">
          <v-icon large>mdi-road-variant</v-icon>
          <span class="pl-2">{{
            $t("ConsoleOrderNew.showOrderDetailDialog_title")
          }}</span>
        </v-card-title>
        <v-card-text class="py-2">
          {{ $t("ConsoleOrderNew.msgShowOrderDetailText") }}</v-card-text
        >
        <v-col cols="12">
          <v-checkbox
            :label="
              $t('ConsoleOrderNew.alwaysShowOrderDetailAlertDialog_label')
            "
            v-model="showOrderDetailDialogEnable"
            :title="
              $t('ConsoleOrderNew.alwaysShowOrderDetailAlertDialog_title')
            "
            dense
            filled
            hide-details
          ></v-checkbox>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.stop="
              (showOrderDetailDialog = false), (setFocusToBoardAddress = true)
            "
            >{{ $t("btnClose") }}</v-btn
          >
          <v-btn
            color="success"
            text
            @click.stop="
              showOrderDetail(clientData.orderId),
                (showOrderDetailDialog = false)
            "
            >{{ $t("ConsoleOrderNew.btnShowOrderDetail") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na zobrazenie zoznamu this.phoneHistory s tlacidlami na zobrazenie getClientByPhone-->
    <v-dialog v-model="showPhoneHistoryDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          <v-icon large>mdi-phone-in-talk</v-icon>
          <span class="pl-2">{{
            $t("ConsoleOrderNew.showPhoneHistoryDialog_title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <!-- Fist number is always random, orange color icon -->
            <v-list-item
              @click="
                (clientByPhoneSearchData.phone = randomPhoneNumber),
                  getClientByPhone(),
                  (showPhoneHistoryDialog = false)
              "
            >
              <v-list-item-icon>
                <v-icon color="orange" v-if="randomPhoneNumber"
                  >mdi-counter</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >{{ randomPhoneNumber }} -
                  {{
                    $t("ConsoleOrderNew.randomPhoneNumber")
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!-- show phone number a after it time of usedAt in format H:mm -->
            <v-list-item
              v-for="(item, i) in phoneHistory"
              :key="i"
              dense
              @click.stop="
                (clientByPhoneSearchData.phone = item.phone),
                  getClientByPhone(),
                  (showPhoneHistoryDialog = false)
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-information-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.phone }} - {{ item.usedAt }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.stop="showPhoneHistoryDialog = false"
            >{{ $t("btnClose") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
    <v-alert prominent type="info" v-model="alert.show">
      <v-row align="center" justify="center">
        <v-col class="grow">
          {{ alert.message }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="runAlertAction(alert.action)" text color="white">
            {{ alert.actionText }}
          </v-btn>
          <v-btn @click="alert.show = false" text color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
import axios from "axios";
import { Status, StatusColor } from "../enums/OrderEnum";
import { clientMark, clientNotification } from "../enums/ConsoleEnum";
import AutocompleteAddressRemote from "../components/AutocompleteAddressRemote.vue";
import AutocompleteAddressRemoteGeocode from "../components/AutocompleteAddressRemoteGeocode.vue";
import AutocompleteAddressOsm from "../components/AutocompleteAddressOsm.vue";
import AutocompleteAddressGeocode from "../components/AutocompleteAddressGeocode.vue";
import AutocompleteCityRemote from "../components/AutocompleteCityRemote.vue";
import AutocompleteDriverRemote from "../components/AutocompleteDriverRemote.vue";
import AutocompleteAccountRemote from "../components/AutocompleteAccountRemote.vue";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import PhoneCountry from "../enums/PhoneCountryEnum";
import { mobilePrefixes } from "../enums/PhoneMobileEnum";

export default {
  name: "OrderNewPanel",
  components: {
    AutocompleteAddressRemote,
    AutocompleteAddressRemoteGeocode,
    AutocompleteAddressOsm,
    AutocompleteAddressGeocode,
    AutocompleteCityRemote,
    AutocompleteDriverRemote,
    AutocompleteAccountRemote,
  },
  props: {
    /* passSelectedMarkerSettedOnMapToNew: {
      type: Array,
      default: [],
    },*/
    passSelectedMarkerSettedOnMapToNew: {
      type: Object,
      default() {
        return {
          displayName: "ON MAP",
          type: "BOARD",
          latitude: "",
          longitude: "",
        };
      },
    },
    passBoundsFromMapToNew: {
      type: Object,
      default() {
        return null;
      },
    },
    passDriverIdFromInfoPanelToNew: {
      type: Number,
      default: 0,
    },
    passDriverIdFromInfoPanelToNewTrigger: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      addressSearchType: "DB_GOOGLE",
      addressSearchTypeOptions: [
        "DB_GOOGLE",
        "DB_OSM", //nebudeme pouzivat
        "DB_GEOCODE",
        "GOOGLE", //nebudeme pouzivat
        "OSM",
        "GEOCODE",
      ],
      selectedAddressComponent: "AutocompleteAddressRemote",
      addressComponentOptions: [
        { text: "DB + Google", value: "AutocompleteAddressRemote" },
        {
          text: "DB + Geocode",
          value: "AutocompleteAddressRemoteGeocode",
        },
        { text: "OSM", value: "AutocompleteAddressOsm" },
        { text: "Geocode", value: "AutocompleteAddressGeocode" },
      ],

      Status,
      StatusColor,
      clientMark,
      clientNotification,
      //Nastavenie ci sa ma do pola s vyhladavnim doplnat medzinarodna predvolba
      presetDefaultDialCode: true,
      defaultPhoneCountryCode: null,
      defaultPhoneDialCode: null,
      presetRandomPhone: false,
      alert: { show: false, message: "", action: null, actionText: "" },
      dialogTransitAddressPanel: null,
      displayOnlyForAdmin: false,
      //nacitane ID taxisluzby z lokalnej pamate
      taxiserviceId: 0,
      //Nastavenia taxisluzby
      taxiserviceSettings: {},
      defaultPresendTime: 0,
      module: moduleDefaultSettings,
      //Nastavenia taxisluzby/sposob platby
      paymentTypeItems: [],
      isDisabledAccountPaymentOption: true, //musi byt v zozname aktivny sposob platby na fakturu/POSTPAID
      //Zistovanie, ci je expansion panel pre transit adresy otvoreny
      transitPanelExpansion: undefined,
      transitPanelExpansionOnetimeShow: false,
      //Zistovanie ci moze byt dynamicky zobrazene okno s novou objednavkou
      createOrderDialogOnetimeShow: false,
      //Automaticky skryvat dialog o uspesnom vytvoreni objednavky
      autoHideSuccessCreateOrderDialog: false,
      autoHideSuccessCreateOrderClearDialog: false,
      //Zobrazit cely uvodny formular
      fullForm: false,
      //Zablokovanie tlacidla na odoslanie objednavky
      loadingCreateOrderButton: false,
      loadingCreateOrderDialogButton: false,
      loadingCreateOrderMultiTimeButton: false,
      //Zablokovanie tlacidiel vytvarania klienta
      loadingCreateClientButton: false,
      //Limitovanie poctu google reverese qeo requestov
      addingAddressReverseGeoOverLimitBan: false,
      //Zoznam klapiek zobrazit ako chips/samostatne tlacidla
      extensionExpand: false,
      //Poloha hlavneho stanovista
      defaultStand: {
        latitude: 0,
        longitude: 0,
      },

      items: [],
      posts: [],
      errors: [],
      extensionList: [],
      accountAllList: [],
      phoneHistory: [],
      randomPhoneNumber: "",

      rulesNote: [
        (v) => v.length <= 80 || this.$t("ConsoleOrderNew.rulesNote"),
      ],
      rulesPresendTime: [
        //v => !!v || "This field is required",
        (v) => (v && v >= 0) || this.$t("ConsoleOrderNew.rulesPresendTime1"), //"Time should be above 1 minute",
        (v) => (v && v <= 240) || this.$t("ConsoleOrderNew.rulesPresendTime2"), // "Max should not be above 240 minutes (4 hours)",
      ],
      defaultNewClientDialog: false,
      nicknameDefault: "",
      notificationDefault: "SMS",
      createClientDialog: false,
      clientCountry: null,
      phoneCountryAlert: false,
      phoneCountryName: "",
      phoneLandlineAlert: false,
      editClientDialog: false,
      infoClientDialog: false,
      historyClientDialog: false,
      createOrderDialogNextShow: false,
      createOrderDialog: false,
      createOrderMultiTimeDialog: false,
      successCreateOrderDialog: false,
      setAccountIdFromClientDialog: false,
      setAccountIdFromClientDialogEnable: true,
      showOrderDetailDialog: false,
      showOrderDetailDialogEnable: true,
      showPhoneHistoryDialog: false,

      //ID objednavky, ktora bola vytvorena
      createdOrderId: null,
      //zablokovanie tlacidla na editovanie udajov o klientovi
      editClientButton: true,
      clientByPhoneSearchData: {
        phone: "",
      },
      clientByExtensionSearchData: {
        extension: "",
      },
      defaultClientData: null,
      clientData: {
        id: null,
        accountId: null,
        mark: "NEW",
        note: "",
        nickname: "",
        notification: "SMS",
        virtualAssistant: false,
        phone: "",
      },
      clientHistoryData: null,
      defaultClientCreateData: null,
      clientCreateData: {
        accountId: null,
        mark: "NEW",
        nickname: "",
        notification: "SMS",
        phone: "",
      },
      defaultClientUpdateData: null,
      clientUpdateData: {
        id: null,
        accountId: null,
        mark: null,
        nickname: null,
        notification: null,
        virtualAssistant: null,
        phone: null,
        note: null,
      },
      defaultOrderCreateData: null,
      orderCreateData: {
        accountId: null,
        brokerId: null,
        clientId: null,
        customerType: "CLIENT",
        preferredDriverId: null,
        filter: null, //bind orderFilter
        note: "",
        paymentType: "CASH",
        presendTime: 0,
        requestedTime: new Date(),
        source: "DISPATCH_TAXISERVICE",
        taxiserviceId: 0,
        type: "INSTANT",
        waypointList: [],
        onlyManualSending: null,
      },
      //Pre multi Time objednavku
      timePickerDialog: false,
      requestedHoursMinutes: null,
      // Definovanie 14 samostatných premenných pomocou polí
      requestedTimeCheck: Array(14).fill(false),
      datePickerDialog: Array(14).fill(false),
      requestedDate: Array(14).fill(null),
      requestedTime: Array(14).fill(null),
      requestedWeekday: Array(14).fill(null),

      //Pre overenie duplicitnej objednavky
      lastClientId: null,
      //Okraje obrazovky mapy pre lepsie vyhladavanie adresy
      addressMapBounds: null,
      //Adresy
      setFocusToBoardAddress: false,
      defaultOrderBoardAddress: null,
      orderBoardAddress: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 1,
        status: "ACTIVE",
        type: "BOARD",
      },
      defaultOrderDestinationAddress: null,
      orderDestinationAddress: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 9,
        status: "ACTIVE",
        type: "DESTINATION",
      },
      setFocusToTransit1Address: false,
      defaultOrderTransit1Address: null,
      orderTransit1Address: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 2,
        status: "ACTIVE",
        type: "TRANSIT",
      },
      defaultOrderTransit2Address: null,
      orderTransit2Address: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 3,
        status: "ACTIVE",
        type: "TRANSIT",
      },
      defaultOrderTransit3Address: null,
      orderTransit3Address: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 4,
        status: "ACTIVE",
        type: "TRANSIT",
      },
      defaultOrderTransit4Address: null,
      orderTransit4Address: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 5,
        status: "ACTIVE",
        type: "TRANSIT",
      },
      defaultOrderFilter: null,
      orderFilter: {
        //airconditioning: "ANY",
        animal: "ANY",
        //autonomous: "ANY",
        childrenChair: "ANY",
        //driverRating: 0,
        //eco: "ANY",
        luggage: "ANY",
        maxPerson: 0,
        paymentTerminal: "ANY",
        //smoking: "ANY",
        specialTransport: "ANY",
      },
      createDefaultItem: {
        name: "",
        service: "",
        //validFrom: moment(),
      },
      createItem: {},
      defaultAddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      defaultBoardAddressModel: null,
      boardAddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      defaultDestAddressModel: null,
      destAddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      defaultTransit1AddressModel: null,
      transit1AddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      defaultTransit2AddressModel: null,
      transit2AddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      defaultTransit3AddressModel: null,
      transit3AddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      defaultTransit4AddressModel: null,
      transit4AddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },

      addressSearchData: {
        query: null,
        countryCode: null,
        city: null,
        taxiserviceId: null,
        //language: "sk",
      },
      //adresa z praveho kliknutia na mape prenesena do componentu s autocomplete adresy
      addBoardAddress: null,
      //Premenne pre Dialogove okno novej objednavky / Preferovany vodic
      //Zoznam vodicov
      driverModel: 0,
      driverSearchData: {
        query: null,
        type: null,
      },
      //Zoznam firiem
      accountSearchData: {
        query: null,
        type: null,
      },
      //Zablokovanie pola na pridanie Firmy zakaznikovi, spravidla v pripade, ak uz ma nejaku firmu pridelenu
      disabledAccountChange: false,
      //Adresa, ktore bude odoslana na server na zaevidovanie do Places, ak bola vyhladana cez google
      newAddress: {
        active: true,
        amenityName: null,
        amenityType: null,
        categoryId: null,
        categoryName: null,
        city: null,
        cityDistrict: null,
        country: "Slovensko",
        countryCode: "SK",
        county: null,
        displayName: null,
        formattedAddress: null,
        houseNumber: null,
        id: null,
        latitude: null,
        longitude: null,
        neighbourhood: null,
        ordering: 0,
        postcode: null,
        priority: 0,
        road: null,
        state: null,
        suburb: null,
      },

      //Objekt pre okamžité označenie objednávky ako onlyManualSending
      defaultOrderSettings: {
        forcedPickupRadius: null,
        onlyManualSending: true,
        showAllAddresses: null,
        showDriverPhoneButton: null,
        showPhone: null,
        showPrice: null,
      },

      //Standardny snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 3000,
      },
      tips: ["fullForm"],
    };
  },

  created() {
    //Nacitat z localstorage ID taxisluzby, lebo kazda taxisluzba si tvori vlasny zoznam adries/Places
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    this.orderCreateData.taxiserviceId = this.taxiserviceId;
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
    //console.log("module", this.module);
  },

  mounted() {
    //Nacitanie role podla ktorej sa potom zobrazuju niektore extra polia
    this.getUserRole();
    //Nacitanie defaulnych hodnot taxisluzby
    this.getTaxiserviceSettings();
    //Nacitanie defaulnych hodnot taxisluzby pre Sposoby platby
    this.getTaxiservicePaymentOptions();
    //Nacitanie zoznamu firiem
    this.getAccountList();
    //Ulozenie si defaultnych dat, ktore sa pouziju pre vycistenie formularov
    var clientCreateData = this.clientCreateData;
    this.nicknameDefault = this.getCookie("nicknameDefault") || "";
    clientCreateData.nickname = this.nicknameDefault;
    this.defaultClientData = Object.assign({}, this.clientData);
    this.defaultClientCreateData = Object.assign({}, clientCreateData);
    this.defaultClientUpdateData = Object.assign({}, this.clientUpdateData);
    this.defaultOrderCreateData = Object.assign({}, this.orderCreateData);
    this.defaultOrderBoardAddress = Object.assign({}, this.orderBoardAddress);
    //console.log("this.defaultOrderBoardAddress", this.defaultOrderBoardAddress);
    this.defaultOrderDestinationAddress = Object.assign(
      {},
      this.orderDestinationAddress
    );
    this.defaultOrderTransit1Address = Object.assign(
      {},
      this.orderTransit1Address
    );
    this.defaultOrderTransit2Address = Object.assign(
      {},
      this.orderTransit2Address
    );
    this.defaultOrderTransit3Address = Object.assign(
      {},
      this.orderTransit3Address
    );
    this.defaultOrderTransit4Address = Object.assign(
      {},
      this.orderTransit4Address
    );
    this.defaultOrderFilter = Object.assign({}, this.orderFilter);
    this.defaultBoardAddressModel = Object.assign(this.defaultAddressModel);
    this.defaultDestAddressModel = Object.assign(this.defaultAddressModel);
    this.defaultTransit1AddressModel = Object.assign(
      {},
      this.defaultAddressModel
    );
    this.defaultTransit2AddressModel = Object.assign(
      {},
      this.defaultAddressModel
    );
    this.defaultTransit3AddressModel = Object.assign(
      {},
      this.defaultAddressModel
    );
    this.defaultTransit4AddressModel = Object.assign(
      {},
      this.defaultAddressModel
    );
    //get this.taxiserviceId again with delay 2000ms
    setTimeout(() => {
      this.getTaxiserviceId();
    }, 2000);
    this.getFullFormFromCookie();
    setTimeout(() => {
      this.alertTip();
      //console.log("this.alertTip");
    }, 4000);
    //get default stand from cookies
    setTimeout(() => {
      this.getDefaultStand();
    }, 5000);
  },

  computed: {
    isDisabledRequestedTime: function () {
      //pre TIME objednavku vratit FALSE, nezablokovane pole pre pozadovany cas objednavky
      return this.orderCreateData.type != "TIME";
    },
    isDisabledCustomerType: function () {
      return this.orderCreateData.customerType != "ACCOUNT";
    },
  },

  watch: {
    /*createOrderDialogNextShow: function () {
      console.log("createOrderDialogNextShow", this.createOrderDialogNextShow);
    },*/
    //Posle udaje o vyhladanych polohach do GroupPanel, aby o dalej odovzdal udaje pre zobrazenie bodov na mape
    boardAddressModel: function () {
      //console.log("showBoardAddressOnMap OrderNewPanel.vue: showBoardAddressOnMap / ", this.boardAddressModel);
      this.$emit("showBoardAddressOnMap", this.boardAddressModel);
      //this.boardAddressModel = this.boardAddressModel[0] ? Object.assign({}, this.boardAddressModel[0]) : null;
      //this.boardAddressModel = this.boardAddressModel[0] ? Object.assign({}, this.boardAddressModel[0]) : null;
      //this.boardAddressModel = this.boardAddressModel[0];
      this.checkBoardDistanceFromStand();
    },
    destAddressModel: function () {
      this.$emit("showDestinationAddressOnMap", this.destAddressModel);
      //Dynamicke otvorenie dialogoveho okna novej objednavky po zadani adresy ciela
      if (
        this.createOrderDialogOnetimeShow == true &&
        this.destAddressModel &&
        this.destAddressModel.latitude &&
        this.destAddressModel.longitude &&
        this.fullForm == false
      ) {
        //console.log("this.destAddressModel", this.destAddressModel);
        this.openCreateOrderDialog();
        this.createOrderDialogOnetimeShow = false;
        this.createOrderDialogNextShow = true;
      }
      if (this.fullForm == true && this.destAddressModel) {
        if (
          this.destAddressModel.displayName &&
          this.destAddressModel.latitude &&
          this.destAddressModel.longitude
        ) {
          this.setAccountIdFromClient();
        }
      }
    },
    transit1AddressModel: function () {
      this.$emit("showTransit1AddressOnMap", this.transit1AddressModel);
    },
    transit2AddressModel: function () {
      this.$emit("showTransit2AddressOnMap", this.transit2AddressModel);
    },
    transit3AddressModel: function () {
      this.$emit("showTransit3AddressOnMap", this.transit3AddressModel);
    },
    transit4AddressModel: function () {
      this.$emit("showTransit4AddressOnMap", this.transit4AddressModel);
    },
    passSelectedMarkerSettedOnMapToNew: function () {
      //console.log("passSelectedMarkerSettedOnMapToNew",this.passSelectedMarkerSettedOnMapToNew);
      //var tempAddressModel;
      //tempAddressModel.latitude = this.passSelectedMarkerSettedOnMapToNew.latitude;
      //tempAddressModel.longitude = this.passSelectedMarkerSettedOnMapToNew.longitude;
      //tempAddressModel.displayName = this.passSelectedMarkerSettedOnMapToNew.displayName;

      //this.boardAddressModel = Object.assign({}, this.boardAddressModel, this.passSelectedMarkerSettedOnMapToNew);
      this.searchAddressApiReverseGeo(
        this.passSelectedMarkerSettedOnMapToNew.latitude,
        this.passSelectedMarkerSettedOnMapToNew.longitude,
        this.passSelectedMarkerSettedOnMapToNew.type
      );
      //console.log("passSelectedMarkerSettedOnMapToNew this.boardAddressModel", this.passSelectedMarkerSettedOnMapToNew.type, this.boardAddressModel);
    },
    passBoundsFromMapToNew: function (newValue) {
      //console.log("passBoundsFromMapToNew", newValue);
      this.addressMapBounds = newValue;
    },
    passDriverIdFromInfoPanelToNewTrigger: function (newValue) {
      //console.log("passDriverIdFromInfoPanelToNew", newValue);
      this.orderCreateData.preferredDriverId =
        this.passDriverIdFromInfoPanelToNew;
    },
  },

  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getTaxiserviceId() {
      if (this.taxiserviceId == null) {
        this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
        this.getTaxiserviceSettings();
      }
    },
    /*clearNote() {
      this.orderCreateData.note = "ddd";
      this.orderCreateData = Object.assign({}, this.orderCreateData)
      console.log("clearNote function: ", this.orderCreateData.note);
    },*/
    getTaxiserviceSettings() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice/${this.taxiserviceId}`
        )
        .then((response) => {
          var tempData = response.data;
          this.taxiserviceSettings = tempData;
          //console.log("getTaxiserviceSettings", response.data);
          //console.log("defaultPresendTime2", this.defaultPresendTime);
          this.orderCreateData.presendTime = tempData.defaultPresendTime || 9;
          this.defaultPresendTime = tempData.defaultPresendTime || 9;
          //Naplnenie ciselnika z klapkami z telefonne ustredne
          if (tempData.phoneExt1) {
            this.extensionList.push(tempData.phoneExt1);
          }
          if (tempData.phoneExt2) {
            this.extensionList.push(tempData.phoneExt2);
          }
          if (tempData.phoneExt3) {
            this.extensionList.push(tempData.phoneExt3);
          }
          if (tempData.phoneExt4) {
            this.extensionList.push(tempData.phoneExt4);
          }
          if (tempData.phoneExt5) {
            this.extensionList.push(tempData.phoneExt5);
          }
          if (tempData.phoneExt6) {
            this.extensionList.push(tempData.phoneExt6);
          }
          if (tempData.phoneExt7) {
            this.extensionList.push(tempData.phoneExt7);
          }
          if (tempData.phoneExt8) {
            this.extensionList.push(tempData.phoneExt8);
          }
          if (tempData.phoneExt9) {
            this.extensionList.push(tempData.phoneExt9);
          }
          if (tempData.phoneExt10) {
            this.extensionList.push(tempData.phoneExt10);
          }
          if (tempData.phoneExt11) {
            this.extensionList.push(tempData.phoneExt11);
          }
          if (tempData.phoneExt12) {
            this.extensionList.push(tempData.phoneExt12);
          }
          //Ak je extensionList prazdny, tak vyplňať medzinárodnú predvoľbu
          if (this.extensionList.length == 0) {
            this.presetDefaultDialCode = true;
          }
          console.log("extensionList", this.extensionList.length);
          console.log("presetDefaultDialCode", this.presetDefaultDialCode);
          //TODO - toto treba prerobiť, keď budú v databáze uložené krajiny ako kódy
          if (tempData.country == "SLOVAKIA") {
            //male pismena "sk"
            this.defaultPhoneCountryCode = "sk";
            this.defaultPhoneDialCode = "+421";
          } else if (tempData.phone1) {
            this.getDefaultCountryFromDispatchPhoneNumber(tempData.phone1);
          }
          console.log("defaultPhoneDialCode", this.defaultPhoneDialCode);
          this.setCookie(
            "defaultPresendTime",
            JSON.stringify(this.defaultPresendTime),
            30
          );
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    getTaxiservicePaymentOptions() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/payment/option`)
        .then((response) => {
          var tempData = response.data;
          //ak tempData obsahuje paymentType POSTPAID, tak odokryt moznost vyberu Accountu
          if (tempData.some((item) => item.paymentType === "POSTPAID")) {
            this.isDisabledAccountPaymentOption = false;
          }
          this.paymentTypeItems = this.$t("paymentTypeArray");
          //ponechat v paymentTypeItems iba tie položky, ktorych value je v zozname tempData (tempData[i].paymentType) a su active (tempData[i].active  == true)
          this.paymentTypeItems = this.paymentTypeItems.filter((item) =>
            tempData.some(
              (item2) => item2.paymentType === item.value && item2.active
            )
          );
          //"CARD_ONLINE" - Kartou cez platobnu branu si moze vybrat iba sam zakaznik v appke
          this.paymentTypeItems = this.paymentTypeItems.filter(
            (item) => item.value !== "CARD_ONLINE"
          );
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    setExtension(extension) {
      //emit event to parent
      this.$emit("setExtension", extension);
    },
    //set fullform to cookie to 6 days
    saveFullFormToCookie() {
      this.setCookie("fullForm", JSON.stringify(this.fullForm), 30);
    },
    getFullFormFromCookie() {
      //Zistenie nastavenie fullForm z Cookies
      var fullForm = this.getCookie("fullForm");
      if (fullForm != undefined || fullForm != null) {
        //console.log("fullForm-exist", fullForm);
        //check if fullForm is true or false
        if (fullForm == "true") {
          this.fullForm = true;
        } else {
          this.fullForm = false;
        }
      } else {
        //console.log("fullForm-no", fullForm);
        this.fullForm = false;
      }
    },

    alertTip() {
      this.alert.message = "";
      this.alert.action = "hide";
      this.alert.actionText = this.$t("ConsoleOrderNew.btnActionHideText");
      this.alert.show = false;

      //Jeden tip denne
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      var lastTipDate = this.getCookie("lastTipDate");
      if (lastTipDate != undefined || lastTipDate != null) {
        if (lastTipDate == today) {
          //console.log("lastTipDate-exist", lastTipDate);
          return;
        }
      }
      this.setCookie("lastTipDate", today, 1);

      //random tip
      var tip = this.tips[Math.floor(Math.random() * this.tips.length)];
      //console.log("tip", tip);

      switch (tip) {
        case "fullForm":
          if (
            this.getCookie("fullForm") == undefined ||
            this.getCookie("fullForm") == null
          ) {
            this.alert.message = this.$t(
              "ConsoleOrderNew.msgActionShowFullForm"
            );
            this.alert.action = "fullForm";
            this.alert.actionText = this.$t(
              "ConsoleOrderNew.btnActionShowText"
            );
            this.alert.show = true;
          }
          break;
        default:
          break;
      }

      //automaticky zavriet alert po 15 sekundach
      setTimeout(() => {
        this.alert.show = false;
      }, 15000);
    },
    runAlertAction(actionType) {
      //console.log("runAlertAction", actionType);
      this.alert.show = false;
      switch (actionType) {
        case "fullForm":
          this.fullForm = true;
          this.saveFullFormToCookie();
          break;
        case "hide":
          this.action.show = false;
          break;
        default:
          this.action.show = false;
          break;
      }
    },
    searchAddressApiReverseGeo(latitude, longitude, type) {
      //REVERSE API SWITCH
      //this.isLoading = true;
      var addressSearchType = this.addressSearchType;
      var addressReverseGeoSearchData = {};
      //addressReverseGeoSearchData.latitude = latitude;
      //addressReverseGeoSearchData.longitude = longitude;

      addressReverseGeoSearchData.latitude = latitude;
      addressReverseGeoSearchData.longitude = longitude;
      //Nacitat z localstorage ID taxisluzby, lebo kazda taxisluzba si tvori vlasny zoznam adries/Places
      addressReverseGeoSearchData.taxiserviceId = this.taxiserviceId;
      //console.log("search reverse geo: ", addressReverseGeoSearchData);
      if (
        addressSearchType == "DB_GOOGLE" ||
        addressSearchType == "DB_OSM" ||
        addressSearchType == "DB_GEOCODE"
      ) {
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/place/reverseGeo`,
            {
              params: addressReverseGeoSearchData,
            }
          )
          .then((res) => {
            //console.log("searchAddressApiReverseGeo", res);
            //this.items = res.data;
            //console.log("this.passSelectedMarkerSettedOnMapToNew.type", this.passSelectedMarkerSettedOnMapToNew.type);
            //console.log("reverseGeoData", res);
            if (res.data) {
              if (res.data.data && res.data.status == "OK") {
                if (type == "BOARD") {
                  //Text adresy sa pouzije vyhladany
                  this.boardAddressModel.displayName =
                    res.data.data.displayName;
                  //console.log("res.displayName", res.data.data.displayName);
                  //Poloha sa pouzije presne ta z mapy ktoru urci dispecer, nie z vyhladanej adresy/place, z nej je iba orientacny text adresy
                  this.boardAddressModel.latitude = latitude;
                  this.boardAddressModel.longitude = longitude;

                  //this.boardAddressModel = this.passSelectedMarkerSettedOnMapToNew;
                  this.boardAddressModel = Object.assign(
                    {},
                    this.boardAddressModel
                  );
                  //Natlaci adresu napr. z reverseGeo do komponentu s autocomplete adresy
                  this.addBoardAddress = this.boardAddressModel;
                  //console.log("addBoardAddress", this.addBoardAddress);
                }
              } else {
                //console.log("not found, search google api");
                if (addressSearchType == "DB_GOOGLE") {
                  this.searchGoogleApiReverseGeo(latitude, longitude);
                } else if (addressSearchType == "DB_OSM") {
                  this.searchOSMReverseGeo(latitude, longitude);
                } else if (addressSearchType == "DB_GEOCODE") {
                  this.searchGeocodeEarthReverseGeo(latitude, longitude);
                }
              }
            } else {
              if (addressSearchType == "DB_GOOGLE") {
                this.searchGoogleApiReverseGeo(latitude, longitude);
              } else if (addressSearchType == "DB_OSM") {
                this.searchOSMReverseGeo(latitude, longitude);
              } else if (addressSearchType == "DB_GEOCODE") {
                this.searchGeocodeEarthReverseGeo(latitude, longitude);
              }
            }
          })
          .catch((err) => {
            //console.log("error response searchAddressApiReverseGeo");
            console.log(err);
            if (addressSearchType == "DB_GOOGLE") {
              this.searchGoogleApiReverseGeo(latitude, longitude);
            } else if (addressSearchType == "DB_OSM") {
              this.searchOSMReverseGeo(latitude, longitude);
            } else if (addressSearchType == "DB_GEOCODE") {
              this.searchGeocodeEarthReverseGeo(latitude, longitude);
            }
          });
      } else if (addressSearchType == "GOOGLE") {
        this.searchGoogleApiReverseGeo(latitude, longitude);
      } else if (addressSearchType == "OSM") {
        this.searchOSMReverseGeo(latitude, longitude);
      } else if (addressSearchType == "GEOCODE") {
        this.searchGeocodeEarthReverseGeo(latitude, longitude);
      }
    },
    //GOOGLE API
    searchGoogleApiReverseGeo(latitude, longitude) {
      //Nastavenie limitu na pocet requestov na 20 za hodinu
      //Zistenie poctu z cookies
      var addingAddressReverseGeoCounter = this.getCookie(
        "addingAddressReverseGeoCounter"
      )
        ? parseInt(JSON.parse(this.getCookie("addingAddressReverseGeoCounter")))
        : 0;
      if (addingAddressReverseGeoCounter > 100) {
        this.addingAddressReverseGeoOverLimitBan = true;
        alert(this.$t("ConsoleOrderNew.msgAddReverseGeoLimitBan"));
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderNew.msgAddReverseGeoLimitBan"
        );
        this.snackbar.color = "error";
      }
      this.setCookie(
        "addingAddressReverseGeoCounter",
        JSON.stringify(addingAddressReverseGeoCounter + 1),
        0.04
      ); //zablokovať na 1 hodinu
      if (this.addingAddressReverseGeoOverLimitBan) {
        return;
      }

      //this.isLoading = true;
      var addressReverseGeoSearchData = {};
      //this.addressReverseGeoSearchData.latitude = latitude;
      //this.addressReverseGeoSearchData.longitude = longitude;
      //addressReverseGeoSearchData.latlng = latitude + "," + longitude;
      //addressReverseGeoSearchData.key = "AIzaSyAdG-Vz3YTYE4F5MTALr6-rU1XbQhD-9Ig";
      //console.log("search reverse geo: ", addressReverseGeoSearchData);

      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(latitude, longitude);
      //const latlng = {lat: latitude, lng: longitude};
      geocoder
        .geocode({ location: latlng })
        .then((response) => {
          //Ak je aspon jeden zaznam, vytiahnut z neho adresu
          if (response.results[0]) {
            var ignore_plus_code;
            var formatted_address;
            //console.log(response.results[0].formatted_address);
            //if (this.passSelectedMarkerSettedOnMapToNew.type == "BOARD") {
            response.results.forEach((element) => {
              ignore_plus_code = false;
              element.address_components.forEach((component) => {
                if (component.types[0] == "plus_code") {
                  //Nechceme registrovat adresy, ktore obsahuju plus_code
                  ignore_plus_code = true;
                  //console.log("plus_code");
                }
              });
              if (ignore_plus_code == false && !formatted_address) {
                //Najst prvu adresu ktora nie je plus_code a iba tu pouzit
                formatted_address = element.formatted_address;
              }
            });

            //Text adresy sa pouzije vyhladany
            this.boardAddressModel.displayName = formatted_address;
            //Poloha sa pouzije presne ta z mapy ktoru urci dispecer, nie z vyhladanej adresy/place, z nej je iba orientacny text adresy
            this.boardAddressModel.latitude =
              this.passSelectedMarkerSettedOnMapToNew.latitude;
            this.boardAddressModel.longitude =
              this.passSelectedMarkerSettedOnMapToNew.longitude;

            //this.boardAddressModel = this.passSelectedMarkerSettedOnMapToNew;
            this.boardAddressModel = Object.assign({}, this.boardAddressModel);
            //}
            //Natlaci adresu napr. z reverseGeo do komponentu s autocomplete adresy
            this.addBoardAddress = this.boardAddressModel;
            //console.log("addBoardAddress", this.addBoardAddress);
            //neukladajme vsetky adresy, lebo v api od google su adresy s indexom vyssim ako 4 spravidla vseobecne (napr. Bratislavsky kraj, Slovensko ALEBO Slovensko)
            var maxAddresses = 0;
            //Zaevidovanie adries z google do nasej DB
            response.results.forEach((element) => {
              //console.log("element address", element);
              maxAddresses++;
              if (maxAddresses < 5) {
                this.addNewAddress(element);
              }
            });
          } else {
            window.alert(
              this.$t("ConsoleOrderNew.msgReverseGeoAlertNoResults")
            ); //No results found
            //Poloha sa pouzije presne ta z mapy ktoru urci dispecer, nie z vyhladanej adresy/place, z nej je iba orientacny text adresy
            this.boardAddressModel.latitude =
              this.passSelectedMarkerSettedOnMapToNew.latitude;
            this.boardAddressModel.longitude =
              this.passSelectedMarkerSettedOnMapToNew.longitude;

            //this.boardAddressModel = this.passSelectedMarkerSettedOnMapToNew;
            this.boardAddressModel = Object.assign({}, this.boardAddressModel);
          }
        })
        .catch((e) => window.alert("Geocoder failed due to: " + e));
      /*axios
        .get(`https://maps.googleapis.com/maps/api/geocode/json`, {
          params: addressReverseGeoSearchData,
        })
        .then((res) => {
          console.log("searchGoogleApiReverseGeo", res);
          //this.items = res.data;
          console.log(
            "this.passSelectedMarkerSettedOnMapToNew.type",
            this.passSelectedMarkerSettedOnMapToNew.type
          );
          if (res.data) {
            if (this.passSelectedMarkerSettedOnMapToNew.type == "BOARD") {
              //Text adresy sa pouzije vyhladany
              this.boardAddressModel.displayName = res.data.displayName;
              console.log("res.displayName", res.data.displayName);
              //Poloha sa pouzije presne ta z mapy ktoru urci dispecer, nie z vyhladanej adresy/place, z nej je iba orientacny text adresy
              this.boardAddressModel.latitude =
                this.passSelectedMarkerSettedOnMapToNew.latitude;
              this.boardAddressModel.longitude =
                this.passSelectedMarkerSettedOnMapToNew.longitude;

              //this.boardAddressModel = this.passSelectedMarkerSettedOnMapToNew;
              this.boardAddressModel = Object.assign(
                {},
                this.boardAddressModel
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this.isLoading = false
          console.log("final Reverse Geo");
        });*/
    },
    //GEOCODE EARTH API
    async searchGeocodeEarthReverseGeo(latitude, longitude) {
      // Nastavenie limitu na počet requestov na 20 za hodinu
      // Zistenie počtu z cookies
      var addingAddressReverseGeoCounter = this.getCookie(
        "addingAddressReverseGeoCounter"
      )
        ? parseInt(JSON.parse(this.getCookie("addingAddressReverseGeoCounter")))
        : 0;
      if (addingAddressReverseGeoCounter > 100) {
        this.addingAddressReverseGeoOverLimitBan = true;
        alert(this.$t("ConsoleOrderNew.msgAddReverseGeoLimitBan"));
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderNew.msgAddReverseGeoLimitBan"
        );
        this.snackbar.color = "error";
      }
      this.setCookie(
        "addingAddressReverseGeoCounter",
        JSON.stringify(addingAddressReverseGeoCounter + 1),
        0.04
      ); // zablokovať na 1 hodinu
      if (this.addingAddressReverseGeoOverLimitBan) {
        return;
      }
      const apiUrl = "https://api.geocode.earth/v1/reverse";
      const apiKey = "ge-946629ec1ed59a19"; // Nahraďte svojím API kľúčom od geocode.earth
      // this.isLoading = true;
      var addressReverseGeoSearchData = {};

      try {
        const response = await axios.get(
          apiUrl +
            `?api_key=` +
            apiKey +
            `&point.lat=${latitude}&point.lon=${longitude}`
        );

        // Ak je aspoň jeden záznam, vytiahnuť z neho adresu
        if (response.data.features.length > 0) {
          // Text adresy sa použije vyhľadaný
          this.boardAddressModel.displayName =
            response.data.features[0].properties.label;
          // Poloha sa použije presne tá z mapy, ktorú určí dispečer, nie z vyhľadanej adresy/place, z nej je iba orientačný text adresy
          this.boardAddressModel.latitude =
            this.passSelectedMarkerSettedOnMapToNew.latitude;
          this.boardAddressModel.longitude =
            this.passSelectedMarkerSettedOnMapToNew.longitude;

          // this.boardAddressModel = this.passSelectedMarkerSettedOnMapToNew;
          this.boardAddressModel = Object.assign({}, this.boardAddressModel);

          // Nátláči adresu napr. z reverseGeo do komponentu s autocomplete adresy
          this.addBoardAddress = this.boardAddressModel;
          // console.log("addBoardAddress", this.addBoardAddress);

          // Zaevidovanie adries z Geocode Earth do našej DB
          response.data.features.forEach((element) => {
            this.addNewAddressGeocodeEarth(element);
          });
        } else {
          window.alert(this.$t("ConsoleOrderNew.msgReverseGeoAlertNoResults")); // No results found
          // Poloha sa použije presne tá z mapy, ktorú určí dispečer, nie z vyhľadanej adresy/place, z nej je iba orientačný text adresy
          this.boardAddressModel.displayName = "";
          this.boardAddressModel.latitude =
            this.passSelectedMarkerSettedOnMapToNew.latitude;
          this.boardAddressModel.longitude =
            this.passSelectedMarkerSettedOnMapToNew.longitude;

          // this.boardAddressModel = this.passSelectedMarkerSettedOnMapToNew;
          this.boardAddressModel = Object.assign({}, this.boardAddressModel);
        }
      } catch (e) {
        window.alert("Geocoder failed due to: " + e);
      }
    },
    //NOMINATIM OSM API
    async searchOSMReverseGeo(latitude, longitude) {
      // Nastavenie limitu na počet requestov na 20 za hodinu
      // Zistenie počtu z cookies
      var addingAddressReverseGeoCounter = this.getCookie(
        "addingAddressReverseGeoCounter"
      )
        ? parseInt(JSON.parse(this.getCookie("addingAddressReverseGeoCounter")))
        : 0;
      if (addingAddressReverseGeoCounter > 100) {
        this.addingAddressReverseGeoOverLimitBan = true;
        alert(this.$t("ConsoleOrderNew.msgAddReverseGeoLimitBan"));
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderNew.msgAddReverseGeoLimitBan"
        );
        this.snackbar.color = "error";
      }
      this.setCookie(
        "addingAddressReverseGeoCounter",
        JSON.stringify(addingAddressReverseGeoCounter + 1),
        0.04
      ); // zablokovať na 1 hodinu
      if (this.addingAddressReverseGeoOverLimitBan) {
        return;
      }
      // const apiUrl = "https://places.taxximo.com/reverse.php";
      const apiUrl = "https://nominatim.openstreetmap.org/reverse";
      const format = "json";

      axios
        /* .//get(process.env.VUE_APP_API_BASE_URL + `/dispatching/place/geo`, {*/
        .get(apiUrl + `?format=${format}&lat=${latitude}&lon=${longitude}`)
        .then((response) => {
          // Ak je nájdený záznam, vytiahnuť z neho adresu
          if (response.data) {
            // Text adresy sa použije vyhľadaný
            this.boardAddressModel.displayName = response.data.display_name;

            // Poloha sa použije presne tá z mapy, ktorú určí dispečer, nie z vyhľadanej adresy/place, z nej je iba orientačný text adresy
            this.boardAddressModel.latitude =
              this.passSelectedMarkerSettedOnMapToNew.latitude;
            this.boardAddressModel.longitude =
              this.passSelectedMarkerSettedOnMapToNew.longitude;

            this.boardAddressModel = Object.assign({}, this.boardAddressModel);

            // Natlaci adresu napr. z reverseGeo do komponentu s autocomplete adresy
            this.addBoardAddress = this.boardAddressModel;

            // Zaevidovanie adresy z Nominatim do našej DB
            //this.addNewAddressNominatim(response.data);
          } else {
            window.alert(
              this.$t("ConsoleOrderNew.msgReverseGeoAlertNoResults")
            ); // No results found
            // Poloha sa použije presne tá z mapy, ktorú určí dispečer, nie z vyhľadanej adresy/place, z nej je iba orientačný text adresy
            this.boardAddressModel.displayName = "";
            this.boardAddressModel.latitude =
              this.passSelectedMarkerSettedOnMapToNew.latitude;
            this.boardAddressModel.longitude =
              this.passSelectedMarkerSettedOnMapToNew.longitude;

            this.boardAddressModel = Object.assign({}, this.boardAddressModel);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    clearAddressSearchData() {
      this.addressSearchData.countryCode = null;
      this.addressSearchData.city = null;
    },

    clearClientData() {
      if (this.presetDefaultDialCode) {
        this.clientByPhoneSearchData.phone = this.defaultPhoneDialCode;
        //Pravdepodobne sa telefon vyplna rucne, teda rovno urobit focus na pole
        setTimeout(() => {
          const textField = this.$refs.phoneField.$refs.input;
          if (textField) {
            textField.focus();
            textField.selectionStart = textField.selectionEnd =
              textField.value.length;
          }
        }, 200);
        //v jednej taxisluzbe nemaju ustrednu a nevypisuju cislo, tak zjednodusenie pre nich
        //nastav this.clientByPhoneSearchData.phone ako cislo +4210000000X pricom X je nahodne cislo
        if (this.presetRandomPhone) {
          let defaultPhoneDialCode = this.defaultPhoneDialCode
            ? this.defaultPhoneDialCode
            : "+421";
          this.clientByPhoneSearchData.phone =
            defaultPhoneDialCode + "00000000" + Math.floor(Math.random() * 10);
        }
      } else {
        this.clientByPhoneSearchData.phone = null;
      }
      this.clientUpdateData.id = null;
      this.clientUpdateData.phone = null;
      this.clientUpdateData.nickname = null;
      this.clientUpdateData.mark = null;
      this.clientUpdateData.notification = null;
      this.clientUpdateData.virtualAssistant = null;
      this.clientUpdateData.note = null;
      this.disabledAccountChange = false;
      this.clientData.id = null;
      this.clientData.phone = null;
      this.clientData.nickname = null;
      this.clientData.mark = null;
      this.clientData.notification = null;
      this.clientData.virtualAssistant = null;
      this.clientData.note = null;
      this.clientData.info = null;
      this.clientCreateData = Object.assign({}, this.defaultClientCreateData);
      this.$refs.formNewOrder.resetValidation();
    },
    clearOrderData() {
      this.createdOrderId = null;
      this.clientData = Object.assign({}, this.defaultClientData);
      this.clearClientData();
      //this.clientCreateData = Object.assign({}, this.defaultClientCreateData);
      this.clientUpdateData = Object.assign({}, this.defaultClientUpdateData);
      this.orderCreateData = Object.assign({}, this.defaultOrderCreateData);
      //Nacitat z localstorage ID taxisluzby, lebo kazda taxisluzba si tvori vlasny zoznam adries/Places
      this.orderCreateData.taxiserviceId = localStorage.getItem(
        "selectedTaxiserviceId"
      );
      this.orderBoardAddress = Object.assign({}, this.defaultOrderBoardAddress);
      this.orderDestinationAddress = Object.assign(
        {},
        this.defaultOrderDestinationAddress
      );
      this.orderTransit1Address = Object.assign(
        {},
        this.defaultOrderTransit1Address
      );
      this.orderTransit2Address = Object.assign(
        {},
        this.defaultOrderTransit2Address
      );
      this.orderTransit3Address = Object.assign(
        {},
        this.defaultOrderTransit3Address
      );
      this.orderTransit4Address = Object.assign(
        {},
        this.defaultOrderTransit4Address
      );
      this.orderFilter = Object.assign({}, this.defaultOrderFilter);
      //this.addBoardAddress = Object.assign({}, this.defaultBoardAddressModel);
      this.boardAddressModel = Object.assign({}, this.defaultBoardAddressModel);
      this.destAddressModel = Object.assign({}, this.defaultDestAddressModel);
      this.transit1AddressModel = Object.assign(
        {},
        this.defaultTransit1AddressModel
      );
      this.transit2AddressModel = Object.assign(
        {},
        this.defaultTransit2AddressModel
      );
      this.transit3AddressModel = Object.assign(
        {},
        this.defaultTransit3AddressModel
      );
      this.transit4AddressModel = Object.assign(
        {},
        this.defaultTransit4AddressModel
      );
      this.$refs.formNewOrder.resetValidation();
    },
    setFocusToTransit1AddressField() {
      //if this.focusToTransit1Address is not setted or empty
      //transitPanelExpansion ak je zbaleny tak dava ako undefined
      //transitPanelExpansionOnetimeShow sa nastavuje pri vyhladani zakaznika na true, tak aby pre kazdeho zakaznika to umoznilo iba 1. krat focus na adresu
      //to je zdovodu, ak by bolo treba adresy editovat, aby sa nedavali zbytocne focusy
      if (
        this.transitPanelExpansionOnetimeShow &&
        this.transitPanelExpansion == undefined
      ) {
        if (this.destAddressModel) {
          if (
            !this.destAddressModel.latitude ||
            !this.destAddressModel.longitude ||
            this.destAddressModel.latitude == null ||
            this.destAddressModel.longitude == null
          ) {
            this.setFocusToTransit1Address = true;
            this.transitPanelExpansionOnetimeShow = false; //aby sa nedavali zbytocne focusy po 2. krat, az ked nacita noveho zakaznika
          }
        } else {
          this.setFocusToTransit1Address = true;
          this.transitPanelExpansionOnetimeShow = false; //aby sa nedavali zbytocne focusy po 2. krat, az ked nacita noveho zakaznika
        }
      }
    },
    getClientByExtension(extension) {
      /*this.clientByPhoneSearchData.phone = "+421948400582";
      this.$refs.phoneField.focus();
      this.getClientByPhone();*/
      //console.log(this.clientByExtensionSearchData.extension);

      //Mala dynamicka logika pre focus policok, ktore by sa mali logicky vyplnat
      this.setFocusToBoardAddress = false;
      this.setFocusToTransit1Address = false;
      this.transitPanelExpansionOnetimeShow = true;
      //Dynamicke (trigger na neskor) zobrazenie dialogoveho okna s novou objednavkou ak je prvy krat vyplnena adresa ciela cesty
      this.createOrderDialogOnetimeShow = true;
      if (extension == "selected") {
        if (
          this.clientByExtensionSearchData.extension == "" ||
          !this.clientByExtensionSearchData.extension
        ) {
          alert(this.$t("ConsoleOrderNew.msgNoExtensionSelected")); //"Nie je vybratá klapka!"
          return;
        } else {
          extension = this.clientByExtensionSearchData.extension;
        }
      } else if (!extension) {
        alert(this.$t("ConsoleOrderNew.msgNoExtensionSelected")); //"Nie je vybratá klapka!"
        return;
      }

      //this.animateProgressBar("start"),
      //Najprv zablokovat tlacidlo na editaciu klienta, lebo este len zistujeme o nom udaje a nevieme ci existuje
      this.editClientButton = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/client/byExtension`,
          { params: { extension: extension } }
        )
        .then((response) => {
          //this.animateProgressBar("ok"), (this.items = response.data);
          if (response.data.status == "OK") {
            //Ak pridu data a klient existuje, tak umoznit vytvorenie objednavky a editaciu udajov o klientovi
            //this.createClientDialog = false;
            this.clientData = response.data.data;
            //Naplnenie aj formulara pre editovanie klienta
            this.clientUpdateData.id = this.clientData.id;
            this.clientUpdateData.phone = this.clientData.phone;
            this.clientUpdateData.nickname = this.clientData.nickname;
            this.clientUpdateData.mark = this.clientData.mark;
            this.clientUpdateData.note = this.clientData.note;
            this.clientUpdateData.notification = this.clientData.notification;
            this.clientUpdateData.virtualAssistant =
              this.clientData.virtualAssistant;
            //na začiatku čísla ak je 00 zameniť za +
            if (response.data.data.phone.substring(0, 2) == "00") {
              this.clientByPhoneSearchData.phone =
                "+" + response.data.data.phone.substring(2);
            } else {
              this.clientByPhoneSearchData.phone = response.data.data.phone;
            }

            this.$refs.formNewOrder.resetValidation();
            //zapamatat si pouzite cislo
            this.addToPhoneHistory(this.clientData.phone);
            //Vytvorenie infa o zakazanikovi
            var accountCounter;
            var accountText;
            //console.log("accountCounter", this.clientData.accountList.length);
            if (this.clientData.accountList.length > 0 && this.module.account) {
              //compare accountAllList with accountList from clientData, if is account in accountList (by accountId) from clientData missing in accountAllList, then remove from clientData.accountList
              for (
                let accountCounter = this.clientData.accountList.length - 1;
                accountCounter >= 0;
                accountCounter--
              ) {
                //console.log("accountCounter",this.clientData.accountList[accountCounter].id);
                var accountEnt = this.clientData.accountList[accountCounter];
                if (
                  !this.accountAllList.some(
                    (account) =>
                      account.accountId ===
                      this.clientData.accountList[accountCounter].id
                  ) ||
                  accountEnt.status === "BLOCKED" ||
                  accountEnt.status === "DISABLED" ||
                  accountEnt.status === "ARCHIVED"
                ) {
                  //console.log("accountCounter remove",this.clientData.accountList[accountCounter].id);
                  this.clientData.accountList.splice(accountCounter, 1);
                }
              }
              if (this.clientData.accountList.length > 0) {
                accountText =
                  " | \uD83C\uDFE2" + this.clientData.accountList[0].name;
                accountCounter = this.clientData.accountList.length;
              }
              //Pre taxisluzby umoznime aby mali k 1 telefonnemu cislu iba maximalne 1 firmu
              this.disabledAccountChange = true;
            } else {
              this.disabledAccountChange = false;
            }
            this.clientData.info =
              this.$t("clientMark." + this.clientData.mark) +
              " | " +
              this.clientData.notification +
              (this.clientData.virtualAssistant ? " | \uD83D\uDC65" : "") +
              " | \u2606" +
              this.clientData.rating +
              " | \u0023" +
              this.clientData.driveCounter +
              accountText +
              (accountCounter > 1
                ? " \u002B" +
                  (accountCounter - 1) +
                  " " +
                  this.$t("ConsoleOrderNew.clientMoreAccounts")
                : "");
            //povolit tlacidlo Editacie
            this.editClientButton = false;
            //Ak existuje prave prebiehajuca objednavka, tak jej detail zobrazit a upozornit na to dispecera
            if (this.clientData.orderId && this.showOrderDetailDialogEnable) {
              this.showOrderDetailDialog = true;
              /*let text =
                  "Klient má prebiehajúcu objednávku!\nChcete zobraziť detail objednávky?";
                if (confirm(text) == true) {
                  this.showOrderDetail(this.clientData.orderId);
                } else {
                  //console.log("Nebol zobrazeny detail prebiehajucej klientovej objednavky");
                  //Dynamicke zobrazenie zoznamu adries z poslednych objednavok klienta
                  this.setFocusToBoardAddress = true;
                }*/
            } else {
              //Dynamicke zobrazenie zoznamu adries z poslednych objednavok klienta
              //setTimeout(() => {
              this.setFocusToBoardAddress = true;
              /*this.$refs.boardAddressSelect.focus();*/
              //}, 200);
            }
            //zobrazit vlajocku statu
            var clientCountry = this.findCountryNameByPhoneNumber(
              this.clientData.phone
            );
            this.clientCountry =
              clientCountry && clientCountry != undefined
                ? clientCountry
                : null;
            this.checkClientMark();
          } else if (response.data.status == "FAILED") {
            //nezobrazit ziadnu vlajocku
            this.clientCountry = null;
            if (response.data.message == "NO_CALL") {
              //alert("Klapka nemá žiadny hovor!");
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderNew.msgNoCallOnExtension"
              ); //"Klapka nemá žiadny hovor!"
              this.snackbar.color = "error";
              this.clearClientData();
            } else if (response.data.message == "NOT_FOUND") {
              this.clearClientData();
              //Ak klient neexistuje v DB, tak zobrazit okno na registraciu noveho klienta
              //na začiatku čísla ak je 00 zameniť za +
              if (response.data.data.phone.substring(0, 2) == "00") {
                this.clientCreateData.phone =
                  "+" + response.data.data.phone.substring(2);
                this.clientByPhoneSearchData.phone =
                  "+" + response.data.data.phone.substring(2);
              } else {
                this.clientCreateData.phone = response.data.data.phone;
                this.clientByPhoneSearchData.phone = response.data.data.phone;
              }
              //zapamatat si pouzite cislo
              this.addToPhoneHistory(this.clientCreateData.phone);
              this.openCreateClientDialog();
              this.notifyClientPhoneCountryCode(this.clientCreateData.phone);
              //zablokovat tlacidlo Editacie
              this.editClientButton = true;
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderNew.msgCreateNewCustomer"
              ); //Zaevidujte nového klienta!
              this.snackbar.color = "warning";
              //zobrazit vlajocku statu
              var clientCountry = this.findCountryNameByPhoneNumber(
                this.clientCreateData.phone
              );
              //console.log("clientCountry", clientCountry);
              this.clientCountry =
                clientCountry && clientCountry != undefined
                  ? clientCountry
                  : null;
            } else if (response.data.message == "PHONE_GATE_ERROR") {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderNew.msgPhoneGateError"
              ); //"Chyba pri komunikácii s telefónnou ústredňou!";
              this.snackbar.color = "error";
            } else {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderNew.msgPhoneGateErrorOther"
              ); //"Chyba pri komunikácii s ústredňou!";
              this.snackbar.color = "error";
            }
          } else {
            //this.createClientDialog = true;
          }
        })
        .catch((e) => {
          //this.animateProgressBar("error"), this.errors.push(e);
          this.openCreateClientDialog();
          //zablokovat tlacidlo Editacie
          this.editClientButton = true;
        });
    },
    getClientByPhone() {
      //console.log(this.clientByPhoneSearchData.phone);
      //Mala dynamicka logika pre focus policok, ktore by sa mali logicky vyplnat
      this.setFocusToBoardAddress = false;
      this.setFocusToTransit1Address = false;
      this.transitPanelExpansionOnetimeShow = true;
      //Dynamicke (trigger na neskor) zobrazenie dialogoveho okna s novou objednavkou ak je prvy krat vyplnena adresa ciela cesty
      this.createOrderDialogOnetimeShow = true;
      //this.$refs.formNewOrder.resetValidation();
      //resetovanie validacie
      //this.$refs.formNewOrder.reset();
      if (!this.clientByPhoneSearchData) {
        alert(this.$t("ConsoleOrderNew.msgPhoneNumberMissing")); //"Missing phone number!"
      } else if (!this.clientByPhoneSearchData.phone) {
        alert(this.$t("ConsoleOrderNew.msgPhoneNumberMissing")); //"Missing phone number!"
      } else if (this.clientByPhoneSearchData.phone.length == 0) {
        alert(this.$t("ConsoleOrderNew.msgPhoneNumberMissing")); //"Missing phone number!"
      } else if (this.clientByPhoneSearchData.phone.substring(0, 1) != "+") {
        alert(this.$t("ConsoleOrderNew.msgPhoneNumberMissingPlus"));
      } else if (this.clientByPhoneSearchData.phone.length < 11) {
        alert(this.$t("ConsoleOrderNew.msgPhoneNumberShort"));
      } else if (isNaN(this.clientByPhoneSearchData.phone.substring(1, 20))) {
        alert(this.$t("ConsoleOrderNew.msgPhoneNumberNotNumbers")); //Phone number must contain only numbers (after '+' character)!
      } else if (
        this.clientByPhoneSearchData.phone.substring(1, 2) == "+" ||
        this.clientByPhoneSearchData.phone.substring(1, 2) == "-"
      ) {
        alert(this.$t("ConsoleOrderNew.msgPhoneNumberSecondNotNumber")); //Second character is not a number!
      } else {
        //zapamatat si pouzite cislo
        this.addToPhoneHistory(this.clientByPhoneSearchData.phone);
        //this.animateProgressBar("start"),
        //Najprv zablokovat tlacidlo na editaciu klienta, lebo este len zistujeme o nom udaje a nevieme ci existuje
        this.editClientButton = true;
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/client/byPhone`,
            {
              params: this.clientByPhoneSearchData,
            }
          )
          .then((response) => {
            //this.animateProgressBar("ok"), (this.items = response.data);
            if (response.data.status == "OK") {
              //Ak pridu data a klient existuje, tak umoznit vytvorenie objednavky a editaciu udajov o klientovi
              //this.createClientDialog = false;
              this.clientData = response.data.data;
              //Naplnenie aj formulara pre editovanie klienta
              this.clientUpdateData.id = this.clientData.id;
              this.clientUpdateData.phone = this.clientData.phone;
              this.clientUpdateData.nickname = this.clientData.nickname;
              this.clientUpdateData.mark = this.clientData.mark;
              this.clientUpdateData.note = this.clientData.note;
              this.clientUpdateData.notification = this.clientData.notification;
              this.clientUpdateData.virtualAssistant =
                this.clientData.virtualAssistant;
              this.$refs.formNewOrder.resetValidation();
              //Vytvorenie infa o zakazanikovi
              var accountCounter;
              var accountText = "";
              //console.log("accountCounter", this.clientData.accountList.length);
              if (
                this.clientData.accountList.length > 0 &&
                this.module.account
              ) {
                //compare accountAllList with accountList from clientData, if is account in accountList (by accountId) from clientData missing in accountAllList, then remove from clientData.accountList
                for (
                  let accountCounter = this.clientData.accountList.length - 1;
                  accountCounter >= 0;
                  accountCounter--
                ) {
                  //console.log("accountCounter",this.clientData.accountList[accountCounter].id);
                  var accountEnt = this.clientData.accountList[accountCounter];
                  if (
                    !this.accountAllList.some(
                      (account) =>
                        account.accountId ===
                        this.clientData.accountList[accountCounter].id
                    ) ||
                    accountEnt.status === "BLOCKED" ||
                    accountEnt.status === "DISABLED" ||
                    accountEnt.status === "ARCHIVED"
                  ) {
                    //console.log("accountCounter removve",this.clientData.accountList[accountCounter].id);
                    this.clientData.accountList.splice(accountCounter, 1);
                  }
                }
                if (this.clientData.accountList.length > 0) {
                  accountText =
                    " | \uD83C\uDFE2" + this.clientData.accountList[0].name;
                  accountCounter = this.clientData.accountList.length;
                }
                //Pre taxisluzby umoznime aby mali k 1 telefonnemu cislu iba maximalne 1 firmu
                this.disabledAccountChange = true;
              } else {
                this.disabledAccountChange = false;
              }
              this.clientData.info =
                this.$t("clientMark." + this.clientData.mark) +
                " | " +
                this.clientData.notification +
                (this.clientData.virtualAssistant ? " | \uD83D\uDC65" : "") +
                " | \u2606" +
                this.clientData.rating +
                " | \u0023" +
                this.clientData.driveCounter +
                accountText +
                (accountCounter > 1
                  ? " \u002B" +
                    (accountCounter - 1) +
                    " " +
                    this.$t("ConsoleOrderNew.clientMoreAccounts")
                  : "");
              //povolit tlacidlo Editacie
              this.editClientButton = false;
              //Ak existuje prave prebiehajuca objednavka, tak jej detail zobrazit a upozornit na to dispecera
              if (this.clientData.orderId && this.showOrderDetailDialogEnable) {
                this.showOrderDetailDialog = true;
                /*let text =
                  "Klient má prebiehajúcu objednávku!\nChcete zobraziť detail objednávky?";
                if (confirm(text) == true) {
                  this.showOrderDetail(this.clientData.orderId);
                } else {
                  console.log("Nebol zobrazeny detail prebiehajucej klientovej objednavky");
                  //Dynamicke zobrazenie zoznamu adries z poslednych objednavok klienta
                  this.setFocusToBoardAddress = true;
                }*/
              } else {
                //Dynamicke zobrazenie zoznamu adries z poslednych objednavok klienta
                //setTimeout(() => {
                this.setFocusToBoardAddress = true;
                /*this.$refs.boardAddressSelect.focus();*/
                //}, 200);
              }
              this.checkClientMark();
            } else if (response.data.status == "FAILED") {
              //Ak klient neexistuje v DB, tak zobrazit okno na registraciu noveho klienta
              this.clientCreateData.nickname = this.nicknameDefault || "";
              this.clientCreateData.mark = "NEW";
              this.clientCreateData.phone = this.clientByPhoneSearchData.phone;
              this.openCreateClientDialog();
              this.notifyClientPhoneCountryCode(this.clientCreateData.phone);
              this.clientData.id = null;
              this.clientData.phone = null;
              this.clientData.nickname = null;
              this.clientData.mark = null;
              this.clientData.notification = null;
              this.clientData.virtualAssistant = null;
              this.clientData.note = null;
              this.clientData.info = null;
              //zablokovat tlacidlo Editacie
              this.editClientButton = true;
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderNew.msgCreateNewCustomer"
              ); //Zaevidujte nového klienta!
              this.snackbar.color = "warning";
            } else {
              //this.createClientDialog = true;
            }
            var clientCountry = this.findCountryNameByPhoneNumber(
              this.clientByPhoneSearchData.phone
            );
            //console.log("clientCountry", clientCountry);
            this.clientCountry =
              clientCountry && clientCountry != undefined
                ? clientCountry
                : null;
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            this.openCreateClientDialog();
            //zablokovat tlacidlo Editacie
            this.editClientButton = true;
            //Nezobrazit vlajocku
            this.clientCountry = null;
          });
      }
    },
    openCreateClientDialog() {
      //this.createItem = Object.assign({}, this.createDefaultItem);
      this.createClientDialog = true;
      //this.$refs.createClientPhone.focus();
    },
    openEditClientDialog() {
      //this.createItem = Object.assign({}, this.createDefaultItem);
      if (
        this.clientData.id == null ||
        this.clientByPhoneSearchData.phone.length < 3
      ) {
        alert(this.$t("ConsoleOrderNew.msgMissingClientOrNumber"));
      } else {
        this.editClientDialog = true;
      }
    },
    openInfoClientDialog() {
      //this.createItem = Object.assign({}, this.createDefaultItem);
      if (
        this.clientData.id == null ||
        this.clientByPhoneSearchData.phone.length < 3
      ) {
        alert(this.$t("ConsoleOrderNew.msgMissingClientOrNumber"));
      } else {
        this.infoClientDialog = true;
      }
    },
    openHistoryClientDialog() {
      //this.createItem = Object.assign({}, this.createDefaultItem);
      if (this.clientData.id == null) {
        alert(this.$t("ConsoleOrderNew.msgMissingClient"));
      } else {
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/client/${this.clientData.id}/order-history`
          )
          .then((response) => {
            //this.animateProgressBar("ok"), (this.items = response.data);
            //Zresetovanie zoznamu objednavok vybrateho klienta
            this.clientHistoryData = null;
            //Vytvorenie zoznamu objednavok vybrateho klienta
            this.clientHistoryData = response.data.data;
            if (this.clientHistoryData.length > 0) {
              this.historyClientDialog = true;
            } else {
              this.snackbar.show = "true";
              this.snackbar.message =
                "História objednávok tohto klienta je prázdna.";
              this.snackbar.color = "error";
            }
          })
          .catch((e) => {
            console.log("e", e);
            //this.animateProgressBar("error"), this.errors.push(e);
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      }
    },

    setDefaultNewClient() {
      //set value to cookie
      this.setCookie("nicknameDefault", this.nicknameDefault, 365);
      var defaultClientCreateData = this.defaultClientCreateData;
      defaultClientCreateData.nickname = this.nicknameDefault;
      this.defaultClientCreateData = Object.assign({}, defaultClientCreateData);
      this.clientCreateData.nickname = this.nicknameDefault;
    },

    openCreateOrderMultiTimeDialog() {
      this.loadingCreateOrderMultiTimeButton = true;
      //this.createItem = Object.assign({}, this.createDefaultItem);
      if (
        this.clientData.id == null ||
        this.clientByPhoneSearchData.phone.length < 3
      ) {
        alert(this.$t("ConsoleOrderNew.msgMissingClientOrNumber"));
        return;
      }
      if (
        this.boardAddressModel.displayName == null ||
        this.boardAddressModel.latitude == null ||
        this.destAddressModel.displayName == null ||
        this.destAddressModel.latitude == null
      ) {
        alert(this.$t("ConsoleOrderNew.msgMissingBoardDestinAddress")); //Nemáte vyhľadnú adresu vyzdvihnutia alebo cieľa cesty.
        return;
      }
      if (this.orderCreateData.type == "TIME") {
        //check if date is in the past
        if (!this.validateRequestedTime(this.orderCreateData.requestedTime)) {
          this.loadingCreateOrderMultiTimeButton = false;
          return;
        }
      }

      //Nacita otvoreny expansion panel ak je aspon jedna tranzitna adresa
      if (
        this.transit1AddressModel.displayName !== null ||
        this.transit2AddressModel.displayName !== null ||
        this.transit3AddressModel.displayName !== null ||
        this.transit4AddressModel.displayName !== null
      ) {
        this.dialogTransitAddressPanel = 0; // 0 = prvy panel
      } else {
        this.dialogTransitAddressPanel = null;
      }
      //z pickera prichádza hodnota ako moment objekt, nie date objekt
      if (!(this.orderCreateData.requestedTime instanceof Date)) {
        this.orderCreateData.requestedTime = new Date(
          this.orderCreateData.requestedTime
        );
      }
      this.requestedHoursMinutes = this.orderCreateData.requestedTime
        .toTimeString()
        .substring(0, 5);

      //Zobrazi okno na vytvorenie novej objednavky s viacerými dátumami
      this.createOrderMultiTimeDialog = true;
      //Nastaviť defaultne hodnoty pre viacere datumy requestedDate[i - 1] so začiatkom this.orderCreateData.requestedTime
      this.requestedDate = [];
      this.requestedTime = [];
      //Id dňa v týždni
      this.requestedWeekday = [];
      for (let i = 1; i <= 14; i++) {
        this.requestedDate.push(
          new Date(
            this.orderCreateData.requestedTime.getFullYear(),
            this.orderCreateData.requestedTime.getMonth(),
            this.orderCreateData.requestedTime.getDate() + i
          )
            .toISOString()
            .substring(0, 10)
        );
        this.requestedTime.push(
          new Date(this.requestedDate[i - 1] + "T" + this.requestedHoursMinutes)
        );
        this.requestedWeekday.push(
          new Date(this.requestedDate[i - 1]).getDay()
        );
      }
      this.loadingCreateOrderMultiTimeButton = false;
    },

    setRequestedMultiTime() {
      //console.log("setRequestedMultiTime");
      for (let i = 1; i <= 14; i++) {
        //console.log(`requestedTime${i}`, this.requestedTime[i - 1]); // Pre debugovanie
        this.requestedTime[i - 1] = new Date(
          this.requestedDate[i - 1] + "T" + this.requestedHoursMinutes
        );
      }
    },
    checkAllRequestedTimeCheck() {
      for (let i = 1; i <= 14; i++) {
        this.$set(this.requestedTimeCheck, i - 1, true);
      }
    },
    checkNoneRequestedTimeCheck() {
      for (let i = 1; i <= 14; i++) {
        this.$set(this.requestedTimeCheck, i - 1, false);
      }
    },
    checkReverseRequestedTimeCheck() {
      for (let i = 1; i <= 14; i++) {
        this.$set(
          this.requestedTimeCheck,
          i - 1,
          !this.requestedTimeCheck[i - 1]
        );
      }
    },
    checkWeekdaysRequestedTimeCheck() {
      for (let i = 1; i <= 14; i++) {
        if (
          this.requestedWeekday[i - 1] == 0 ||
          this.requestedWeekday[i - 1] == 6
        ) {
          this.$set(this.requestedTimeCheck, i - 1, false);
        } else {
          this.$set(this.requestedTimeCheck, i - 1, true);
        }
      }
    },
    checkWeekendRequestedTimeCheck() {
      for (let i = 1; i <= 14; i++) {
        if (
          this.requestedWeekday[i - 1] == 0 ||
          this.requestedWeekday[i - 1] == 6
        ) {
          this.$set(this.requestedTimeCheck, i - 1, true);
        } else {
          this.$set(this.requestedTimeCheck, i - 1, false);
        }
      }
    },

    openCreateOrderDialog() {
      //this.createItem = Object.assign({}, this.createDefaultItem);
      if (
        this.clientData.id == null ||
        this.clientByPhoneSearchData.phone.length < 3
      ) {
        alert(this.$t("ConsoleOrderNew.msgMissingClientOrNumber"));
        return;
      }
      if (
        this.boardAddressModel.displayName == null ||
        this.boardAddressModel.latitude == null ||
        this.destAddressModel.displayName == null ||
        this.destAddressModel.latitude == null
      ) {
        alert(this.$t("ConsoleOrderNew.msgMissingBoardDestinAddress")); //Nemáte vyhľadnú adresu vyzdvihnutia alebo cieľa cesty.
        return;
      }

      //Nacita otvoreny expansion panel ak je aspon jedna tranzitna adresa
      if (
        this.transit1AddressModel.displayName !== null ||
        this.transit2AddressModel.displayName !== null ||
        this.transit3AddressModel.displayName !== null ||
        this.transit4AddressModel.displayName !== null
      ) {
        this.dialogTransitAddressPanel = 0; // 0 = prvy panel
      } else {
        this.dialogTransitAddressPanel = null;
      }
      //Zobrazi okno na vytvorenie novej objednavky, aby sa zabezpecil workflow, ze najpr sa zobrazí okno na pridanie Firmy ku klientovi
      this.setAccountIdFromClient();
    },
    createClient() {
      this.loadingCreateClientButton = true;
      //console.log(OrderStatus.NEW),
      //this.animateProgressBar("start"),
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/client/createClient`,
          this.clientCreateData
        )
        .then((response) => {
          //this.animateProgressBar("ok"), (this.items = response.data);
          if (response.data.status == "OK") {
            this.clientData = response.data.data;
            //Naplnenie aj formulara pre editovanie klienta
            this.clientUpdateData.id = this.clientData.id;
            this.clientUpdateData.phone = this.clientData.phone;
            this.clientUpdateData.nickname = this.clientData.nickname;
            this.clientUpdateData.mark = this.clientData.mark;
            this.clientUpdateData.note = this.clientData.note;
            this.clientUpdateData.notification = this.clientData.notification;
            this.clientUpdateData.virtualAssistant =
              this.clientData.virtualAssistant;
            // getClientByPhone();
            this.createClientDialog = false;
            //povolit tlacidlo Editacie
            this.editClientButton = false;
          } else if (response.data.status == "FAILED") {
            //this.createClientDialog = true;
            //zablokovat tlacidlo Editacie
            this.editClientButton = true;
            if (response.data.message == "ALREADY_EXISTS") {
              this.snackbar.show = "true";
              this.snackbar.message = "Telefónne číslo už existuje.";
              this.snackbar.color = "error";
            }
          } else {
            //this.createClientDialog = true;
          }
          this.loadingCreateClientButton = false;
        })
        .catch((e) => {
          this.loadingCreateClientButton = false;
          //this.animateProgressBar("error"), this.errors.push(e);
          this.openCreateClientDialog();
        });
    },
    updateClientNote(clientNote) {
      //console.log(OrderStatus.NEW),
      //this.animateProgressBar("start"),
      if (this.clientData.id == null) {
        alert(this.$t("ConsoleOrderNew.msgMissingClient"));
      } else if (this.clientUpdateData.note == clientNote) {
        alert(this.$t("ConsoleOrderNew.msgClientNoteNotChanged"));
      } else {
        this.clientUpdateData.note = clientNote;
        this.updateClient();
      }
    },
    updateClient() {
      //console.log(OrderStatus.NEW),
      //this.animateProgressBar("start"),
      if (this.clientData.id == null) {
        alert(this.$t("ConsoleOrderNew.msgMissingClient"));
      } else {
        var nickName = this.clientUpdateData.nickname.toLowerCase();
        if (
          nickName.includes("cigán") ||
          nickName.includes("cigan") ||
          nickName.includes("kurva") ||
          nickName.includes("piča") ||
          nickName.includes("kokot") ||
          nickName.includes("ojebal") ||
          nickName.includes("ojebáva") ||
          nickName.includes("cigánky") ||
          nickName.includes("ciganky") ||
          nickName.includes("cigáni") ||
          nickName.includes("cigani") ||
          nickName.includes("feťák") ||
          nickName.includes("fetak") ||
          nickName.includes("feťáci") ||
          nickName.includes("debil") ||
          nickName.includes("idiota") ||
          nickName.includes("idiot") ||
          nickName.includes("orecháč") ||
          nickName.includes("orechac") /* ||
          nickName.includes("dlžný") ||
          nickName.includes("dlzny") ||
          nickName.includes("dlžná") ||
          nickName.includes("dlzna") ||
          nickName.includes("dlžia") */
        ) {
          this.snackbar.show = "true";
          this.snackbar.message =
            "Pozor, v mene klienta sa nachádza nevhodné slovo!!! Môže to vidieť zákazník na telefóne vo vozidle. Požadovný popis radšej píšte do poznámky.";
          this.snackbar.color = "error";
        }
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/client/updateClient`,
            this.clientUpdateData
          )
          .then((response) => {
            //this.animateProgressBar("ok"), (this.items = response.data);
            if (response.data.status == "OK") {
              this.clientData = response.data.data;
              //Naplnenie aj formulara pre editovanie klienta
              this.clientUpdateData.id = this.clientData.id;
              this.clientUpdateData.phone = this.clientData.phone;
              this.clientUpdateData.nickname = this.clientData.nickname;
              this.clientUpdateData.mark = this.clientData.mark;
              this.clientUpdateData.note = this.clientData.note;
              this.clientUpdateData.notification = this.clientData.notification;
              this.clientUpdateData.virtualAssistant =
                this.clientData.virtualAssistant;
              //Vytvorenie infa o zakazanikovi
              var accountCounter = null;
              var accountText = "";
              //console.log("accountCounter", this.clientData.accountList.length);
              if (this.clientData.accountList.length > 0) {
                accountText =
                  " | \uD83C\uDFE2" + this.clientData.accountList[0].name;
                accountCounter = this.clientData.accountList.length;
              }
              this.clientData.info =
                this.$t("clientMark." + this.clientData.mark) +
                " | " +
                this.clientData.notification +
                (this.clientData.virtualAssistant ? " | \uD83D\uDC65" : "") +
                " | \u2606" +
                this.clientData.rating +
                " | \u0023" +
                this.clientData.driveCounter +
                accountText +
                (accountCounter > 1
                  ? " \u002B" +
                    (accountCounter - 1) +
                    " " +
                    this.$t("ConsoleOrderNew.clientMoreAccounts")
                  : "");
              // getClientByPhone();
              this.editClientDialog = false;
              //povolit tlacidlo Editacie
              this.editClientButton = false;
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderNew.msgUpdateClientSuccess"
              );
              this.snackbar.color = "success";
            } else if (response.data.status == "FAILED") {
              //this.createClientDialog = true;
              //zablokovat tlacidlo Editacie
              this.editClientButton = true;
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderNew.msgUpdateClientError"
              );
              this.snackbar.color = "warning";
            } else {
              //this.createClientDialog = true;
            }
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            this.openCreateClientDialog();
            this.editClientButton = true;
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "ConsoleOrderNew.msgUpdateClientError"
            );
            this.snackbar.color = "warning";
          });
      }
    },
    animateProgressBar(progressStatus) {
      if (progressStatus == "start") {
        this.loading = true;
        this.loadingIndeterminate = false;
      } else if (progressStatus == "ok") {
        this.loading = false;
        this.loadingIndeterminate = false;
      } else if (progressStatus == "error") {
        //v pripade chyby sa prepne do rezimu animovaneho loadingu, kym sa neuskotocni uspesny pokus o ziskanie udajov
        this.loadingIndeterminate = true;
      }
    },
    setRequestedTime() {
      //Pre INSTANT objednavku neposielat cas
      if (this.orderCreateData.type == "INSTANT") {
        this.orderCreateData.requestedTime = new Date();
        this.orderCreateData.presendTime = 0;
      } else if (this.orderCreateData.type == "TIME") {
        this.orderCreateData.requestedTime = new Date();
        //set requestedTime in future (current time + defaultPresendTime) to whole minutes (round up)
        this.orderCreateData.requestedTime.setMinutes(
          this.orderCreateData.requestedTime.getMinutes() +
            5 +
            this.defaultPresendTime
        );
        this.orderCreateData.requestedTime.setSeconds(0);
        this.orderCreateData.requestedTime.setMilliseconds(0);
        this.setPresendTime();
      }
    },
    setPresendTime() {
      //Ak nemame nastaveny defaultStand, tak nastavime priamo defaultPresendTime
      if (this.defaultStand.latitude == 0 && this.defaultStand.longitude == 0) {
        this.orderCreateData.presendTime = this.defaultPresendTime;
      } else if (
        this.boardAddressModel.longitude &&
        this.boardAddressModel.latitude
      ) {
        var distance = 0;
        var time = this.defaultPresendTime;
        var lat1 = this.defaultStand.latitude;
        var lon1 = this.defaultStand.longitude;
        var lat2 = this.boardAddressModel.latitude;
        var lon2 = this.boardAddressModel.longitude;
        //Vypocet vzdialenosti medzi defaultStand a poziciou zakaznika
        var R = 6371; // Radius of the earth in km
        var dLat = (lat2 - lat1) * (Math.PI / 180);
        var dLon = (lon2 - lon1) * (Math.PI / 180);
        var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        distance = R * c * 1000; // Distance in m
        //convert distance to number and round to integer
        distance = Number(distance.toFixed(0));
        //Vypocet casu na zaklade vzdialenosti, za kazdych 1000 metrov 1 minuta
        time = Math.round(distance / 1000) + 10; // +10 minut na cakanie a nastup
        //console.log("time from stand to waypoint: " + time);
        //Ak je cas vacsi ako defaultPresendTime (+ 2 min tolerancia),
        //tak nastavime odhadovany cas, inak presendTime
        if (time > this.defaultPresendTime + 2) {
          //get distance from OSM ROUTING API, example: https://routing.taxximo.com/route/v1/driving/18.06607,48.31224;18.066567,48.311218?steps=false
          //var tempTime = this.getDistanceDurationFromOSM(lat1,lon1,lat2,lon2);
          //tempTime = tempTime.duration;
          //time = Math.round(tempTime / 60) + 10; // +10 minut na cakanie a nastup
          this.orderCreateData.presendTime = time;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "ConsoleOrderNew.msgPresendTimeNotSufficient"
          );
          this.snackbar.color = "warning";
        } else {
          this.orderCreateData.presendTime = this.defaultPresendTime;
        }
      } else {
        this.orderCreateData.presendTime = this.defaultPresendTime;
      }
    },
    checkBoardDistanceFromStand() {
      //Ak nie je pri vyhladavani adresy nastavená krajina a teda aj mesto (dispecer by hladal ulicu v inom meste ako vlastnom),
      //tak nemame garanciu, ci si dispecer omylom nevybral inu adresu v inom meste
      if (
        this.defaultStand.latitude > 0 &&
        this.defaultStand.longitude > 0 &&
        this.boardAddressModel.longitude &&
        this.boardAddressModel.latitude &&
        !this.addressSearchData.countryCode &&
        !this.addressSearchData.city
      ) {
        var distance = 0;
        var lat1 = this.defaultStand.latitude;
        var lon1 = this.defaultStand.longitude;
        var lat2 = this.boardAddressModel.latitude;
        var lon2 = this.boardAddressModel.longitude;
        //Vypocet vzdialenosti medzi defaultStand a poziciou zakaznika
        var R = 6371; // Radius of the earth in km
        var dLat = (lat2 - lat1) * (Math.PI / 180);
        var dLon = (lon2 - lon1) * (Math.PI / 180);
        var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        distance = R * c * 1000; // Distance in m
        //convert distance to number and round to integer
        distance = Number(distance.toFixed(0));
        if (distance > 20000) {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "ConsoleOrderNew.msgBoardAddressFarFromStand"
          );
          this.snackbar.color = "warning";
        }
      }
    },

    //get distance from OSM ROUTING API, example: https://routing.taxximo.com/route/v1/driving/18.06607,48.31224;18.066567,48.311218?steps=false
    getDistanceDurationFromOSM(lat1, lon1, lat2, lon2) {
      var url =
        "https://routing.taxximo.com/route/v1/driving/" +
        lon1 +
        "," +
        lat1 +
        ";" +
        lon2 +
        "," +
        lat2 +
        "?steps=false";
      console.log("url: " + url);
      axios
        .get(url)
        .then((response) => {
          return response.data.routes[0];
        })
        .catch((e) => {
          console.log("error: " + e);
        });
    },

    setPaymentTerminalFromPayment() {
      if (
        this.orderCreateData.paymentType == "CARD" &&
        this.orderFilter.paymentTerminal != "YES"
      ) {
        this.orderFilter.paymentTerminal = "YES";
        //show snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t(
          "ConsoleOrderNew.msgFilterPaymentTerminalYes"
        ); //Pridaná požiadavka na platobný terminál vo vozidle
        this.snackbar.color = "warning";
      } else if (
        this.orderCreateData.paymentType != "CARD" &&
        this.orderFilter.paymentTerminal == "YES"
      ) {
        this.orderFilter.paymentTerminal = "ANY";
        //show snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t(
          "ConsoleOrderNew.msgFilterPaymentTerminalAny"
        ); //Zrušená požiadavka na platobný terminál vo vozidle
        this.snackbar.color = "warning";
      }
    },
    setAccountId() {
      //overit ci this.paymentTypeItems obsahuje nejake hodnoty. Ak nie, return a alert
      if (this.paymentTypeItems.length == 0) {
        alert(this.$t("ConsoleOrderNew.msgGetPaymentListError")); //Nastala chyba pri načítavaní možností platby. Skontrolujte či sú nakonfigurované platobné metódy.
        return;
      }
      //Pre nefiremnu objednavku neposielat ID firmy
      if (this.orderCreateData.customerType == "CLIENT") {
        this.orderCreateData.accountId = null;
        //ak array paymentTypeItems obsahuje hodnotu CASH, nastavit ju na CASH, ak nie tak nastaviť na hodnotu prvý v poradí ([0])
        if (this.paymentTypeItems.map((item) => item.value).includes("CASH")) {
          this.orderCreateData.paymentType = "CASH";
        } else {
          this.orderCreateData.paymentType = this.paymentTypeItems[0];
        }
      } else if (this.orderCreateData.customerType == "ACCOUNT") {
        this.orderCreateData.paymentType = "POSTPAID";
      }
      this.setPaymentTerminalFromPayment();
    },
    setPaymentOption() {
      //Pre nefiremnu objednavku neposielat ID firmy
      if (this.orderCreateData.paymentType == "POSTPAID") {
        this.orderCreateData.customerType = "ACCOUNT";
        this.setAccountId();
      }
      this.setPaymentTerminalFromPayment();
    },
    setAccountIdFromClient() {
      //Ak neexistuje client, alebo nebol vybratý, ukočiť funkciu
      if (this.clientData == null || this.clientData.id == null) {
        return;
      }
      //Ak ma klient moznost firemnej jazdy, doplnit ID firmy
      if (this.clientData.accountList) {
        if (
          this.clientData.accountList.length > 0 &&
          this.isDisabledAccountPaymentOption == false &&
          this.module.account &&
          this.setAccountIdFromClientDialogEnable
        ) {
          this.setAccountIdFromClientDialog = true;
        } else {
          if (this.createOrderDialogNextShow == true) {
            this.createOrderDialog = true;
          }
        }
      } else {
        if (this.createOrderDialogNextShow == true) {
          this.createOrderDialog = true;
        }
      }
    },

    setClientPaymentTypeConfirm() {
      //Ak neexistuje client, alebo nebol vybratý, ukočiť funkciu
      if (this.clientData == null || this.clientData.id == null) {
        return;
      }
      this.orderCreateData.customerType = "CLIENT";
      this.setAccountId();
      //pre workflow s popup okonom na vytvorenie novej objednavk
      this.setAccountIdFromClientDialog = false;
      if (this.createOrderDialogNextShow == true) {
        this.createOrderDialog = true;
      }
    },

    setAccountIdFromClientConfirm() {
      //Ak neexistuje client, alebo nebol vybratý, ukočiť funkciu
      if (this.clientData == null || this.clientData.id == null) {
        return;
      }
      //Ak ma klient moznost firemnej jazdy, doplnit ID firmy
      if (
        this.clientData.accountList.length > 0 &&
        this.isDisabledAccountPaymentOption == false &&
        this.module.account
      ) {
        //musi byt v zozname PaymentOption hodnota POSTPAID
        if (this.isDisabledAccountPaymentOption == false) {
          //musi byt v zozname PaymentOption hodnota POSTPAID
          this.orderCreateData.customerType = "ACCOUNT";
          //Overit či this.clientData.accountList[0].id sa nachadza v zozname  this.accountAllList
          //check if value "this.clientData.accountList[0].id" is in array "this.accountAllList" (this.accountAllList.accountId )
          if (
            this.accountAllList.find(
              (o) => o.accountId === this.clientData.accountList[0].id
            ) != null
          ) {
            this.setAccountId();
            //this.orderCreateData.accountId = Number(this.clientData.accountList[0].id);
            this.orderCreateData.accountId = this.clientData.accountList[0].id;
            if (this.clientData.accountList[0].status == "PENDING") {
              //show snackbar
              this.snackbar.show = true;
              this.snackbar.message = this.$t(
                "ConsoleOrderNew.msgClientPendingOnAccount"
              ); //Firma bola pridaná, ale zákazník ešte nebol v danej firme schválený.
              this.snackbar.color = "warning";
            }
          } else {
            this.orderCreateData.accountId = null;
            alert(this.$t("ConsoleOrderNew.msgDefaultAccountIsNotValid")); //Prednastavená firma nie je platná. Vyberte si prosím firmu z ponuky a oznámte manažérovi taxislužby, aby skontroloval firemného zákazníka.
          }
          //pre workflow s popup okonom na vytvorenie novej objednavky
          this.setAccountIdFromClientDialog = false;
          if (this.createOrderDialogNextShow == true) {
            this.createOrderDialog = true;
          }
        }
      }
    },

    getAccountList() {
      //console.log("getAccountList", this.module.account);
      if (this.module.account == true) {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/account`)
          .then((res) => {
            //console.log("this.accountAllList", res);
            this.accountAllList = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.accountAllList = [];
      }
    },
    createOrderMultiTime() {
      this.loadingCreateOrderMultiTimeButton = true;
      console.log("createOrderMultiTime");
      console.log("this.orderCreateData.type", this.orderCreateData.type);
      console.log("this.orderCreateData", this.orderCreateData.requestedTime);
      if (this.orderCreateData.type == "TIME") {
        //check if date is in the past
        if (!this.validateRequestedTime(this.orderCreateData.requestedTime)) {
          this.loadingCreateOrderMultiTimeButton = false;
          return;
        }
      }
      this.setRequestedMultiTime();
      for (let i = 1; i <= 14; i++) {
        //if requestedTimeCheck is checked, then create order
        if (this.requestedTimeCheck[i - 1] == true) {
          const time = this.requestedTime[i - 1] || null;
          if (time && !this.validateRequestedTime(time)) {
            this.loadingCreateOrderMultiTimeButton = false;
            return;
          }
          if (time) {
            //chech if valid time as iso datetime
            if (time.toISOString().length == 24) {
              this.createOrder(time);
            } else {
              alert(
                this.$t("ConsoleOrderNew.msgRequestedTimeNotValid") + " " + time
              );
              return;
            }
          }
        }
      }
      //Loop requestedTimeXX from 01 to 14 and validate, if date is valid and minimum 5 minutes in future. If not, show alert and return
      /*  for (let i = 1; i < 15; i++) {
        var requestedTime = "requestedTime" + ("0" + i).slice(-2); */

      /*
        if (this.orderCreateData.type == "TIME") {
        //check if date is in the past
        if (this.orderCreateData.requestedTime < new Date()) {
          alert(this.$t("ConsoleOrderNew.msgTimeInPastError")); //Zadaný čas je v minulosti!
          return;
        }
        //check if date is in the future minimum 5 minutes
        if (
          this.orderCreateData.requestedTime <
          new Date(new Date().getTime() + 5 * 60000)
        ) {
          alert(this.$t("ConsoleOrderNew.msgTimeTooSoonError")); //Zadaný čas je príliš skoro! Zadajte neskorší čas (aspoň o 5 minút)!
          return;
        }
        //check if date is max 2 weeks in future
        if (
          this.orderCreateData.requestedTime >
          new Date().setDate(new Date().getDate() + 16)
        ) {
          alert(this.$t("ConsoleOrderNew.msgTimeTooLateError")); //Zadaný čas je viac ako 2 týždne v budúcnosti!
          return;
        }
      }
  */
      /*         if (this.orderCreateData[requestedTime] != null) {
          if (
            this.orderCreateData[requestedTime] < new Date() ||
            this.orderCreateData[requestedTime] == null
          ) {
            alert(
              this.$t("ConsoleOrderNew.msgRequestedTimeNotValid") +
                " " +
                requestedTime
            );
            return;
          }
        }
      } */

      //if requesteTime01 is valid Date, call createOrder with requestedTime = requestedTime01. Then loop for requestedTime02, requestedTime03, requestedTime04 until 14
    },

    createOrder(requestedDateTime) {
      //requesteDateTime parameter z multi nastavovania casu
      this.orderCreateData.requestedTime = requestedDateTime
        ? requestedDateTime
        : this.orderCreateData.requestedTime;

      if (this.module.dispatchingCreateOrder == false) {
        alert(
          this.$t("ConsoleOrderNew.msgDisabledModuelDispatchingCreateOrder")
        );
        return;
      }
      if (
        this.clientData.id == null ||
        this.clientByPhoneSearchData.phone.length < 5
      ) {
        alert(this.$t("ConsoleOrderNew.msgMissingClientOrNumber"));
        return;
      }
      if (this.clientByPhoneSearchData.phone != this.clientData.phone) {
        alert(this.$t("ConsoleOrderNew.msgClientPhoneNotMatch"));
        return;
      }
      if (
        !this.orderCreateData.accountId &&
        this.orderCreateData.paymentType == "POSTPAID"
      ) {
        alert(this.$t("ConsoleOrderNew.msgAccountNotSelected")); //Vybrali ste platbu na firmu, ale nevybrali ste firmu.
        return;
      }
      if (
        this.boardAddressModel.displayName == null ||
        this.boardAddressModel.latitude == null ||
        this.destAddressModel.displayName == null ||
        this.destAddressModel.latitude == null
      ) {
        alert(this.$t("ConsoleOrderNew.msgMissingBoardDestinAddress"));
        return;
      }
      //console.log(OrderStatus.NEW),
      //this.animateProgressBar("start"),
      this.orderCreateData.taxiserviceId = localStorage.getItem(
        "selectedTaxiserviceId"
      );
      this.orderCreateData.clientId = this.clientData.id;
      this.orderCreateData.filter = this.orderFilter;
      this.orderBoardAddress.address = this.boardAddressModel.displayName;
      this.orderBoardAddress.latitude = this.boardAddressModel.latitude;
      this.orderBoardAddress.longitude = this.boardAddressModel.longitude;
      this.orderTransit1Address.address = this.transit1AddressModel.displayName;
      this.orderTransit1Address.latitude = this.transit1AddressModel.latitude;
      this.orderTransit1Address.longitude = this.transit1AddressModel.longitude;
      this.orderTransit2Address.address = this.transit2AddressModel.displayName;
      this.orderTransit2Address.latitude = this.transit2AddressModel.latitude;
      this.orderTransit2Address.longitude = this.transit2AddressModel.longitude;
      this.orderTransit3Address.address = this.transit3AddressModel.displayName;
      this.orderTransit3Address.latitude = this.transit3AddressModel.latitude;
      this.orderTransit3Address.longitude = this.transit3AddressModel.longitude;
      this.orderTransit4Address.address = this.transit4AddressModel.displayName;
      this.orderTransit4Address.latitude = this.transit4AddressModel.latitude;
      this.orderTransit4Address.longitude = this.transit4AddressModel.longitude;
      this.orderDestinationAddress.address = this.destAddressModel.displayName;
      this.orderDestinationAddress.latitude = this.destAddressModel.latitude;
      this.orderDestinationAddress.longitude = this.destAddressModel.longitude;
      this.orderCreateData.waypointList = [];
      if (this.orderBoardAddress.longitude && this.orderBoardAddress.latitude) {
        this.orderCreateData.waypointList.push(this.orderBoardAddress);
      }
      if (
        this.orderTransit1Address.longitude &&
        this.orderTransit1Address.latitude
      ) {
        this.orderCreateData.waypointList.push(this.orderTransit1Address);
      }
      if (
        this.orderTransit2Address.longitude &&
        this.orderTransit2Address.latitude
      ) {
        this.orderCreateData.waypointList.push(this.orderTransit2Address);
      }
      if (
        this.orderTransit3Address.longitude &&
        this.orderTransit3Address.latitude
      ) {
        this.orderCreateData.waypointList.push(this.orderTransit3Address);
      }
      if (
        this.orderTransit4Address.longitude &&
        this.orderTransit4Address.latitude
      ) {
        this.orderCreateData.waypointList.push(this.orderTransit4Address);
      }
      if (
        this.orderDestinationAddress.longitude &&
        this.orderDestinationAddress.latitude
      ) {
        this.orderCreateData.waypointList.push(this.orderDestinationAddress);
      }
      //skontrolovat, ci niektorý waypoint nie je vzdialeny menej ako 200m od predchadzajuceho waypointu
      var tempWaypointList = [];
      tempWaypointList = this.orderCreateData.waypointList;
      for (let i = 0; i < this.orderCreateData.waypointList.length - 1; i++) {
        var distance = 0;
        var lat1 = tempWaypointList[i].latitude;
        var lon1 = tempWaypointList[i].longitude;
        var lat2 = tempWaypointList[i + 1].latitude;
        var lon2 = tempWaypointList[i + 1].longitude;
        //check if waypoints are not too close
        var R = 6371; // Radius of the earth in km
        var dLat = (lat2 - lat1) * (Math.PI / 180);
        var dLon = (lon2 - lon1) * (Math.PI / 180);
        var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        distance = R * c * 1000; // Distance in m
        //convert distance to number and round to integer
        distance = Number(distance.toFixed(0));
        //console.log(distance +" " +this.orderCreateData.waypointList[i].address +" " +this.orderCreateData.waypointList[i + 1].address);
        if (distance < 10) {
          alert(
            this.$t("ConsoleOrderNew.msgShortDistanceAddresses") +
              " \n- " +
              this.orderCreateData.waypointList[i].address +
              "\n- " +
              this.orderCreateData.waypointList[i + 1].address
          );
          return;
        }
      }
      //this.orderCreateData.preferredDriverId = this.driverModel;
      if (
        this.orderCreateData.type === "TIME" &&
        !this.validateRequestedTime(this.orderCreateData.requestedTime)
      ) {
        return;
      } else if (this.orderCreateData.type == "INSTANT") {
        this.orderCreateData.requestedTime = new Date();
      }
      this.loadingCreateOrderButton = true;
      this.loadingCreateOrderDialogButton = true;
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/order`,
          this.orderCreateData
        )
        .then((response) => {
          //this.animateProgressBar("ok"), (this.items = response.data);
          // this.destAddressModel.displayName,
          var resultData = response.data;
          this.createOrderDialog = false;
          this.createOrderMultiTimeDialog = false;
          if (response.data) {
            //console.log("resultData.length", resultData.length);
            //kontrola ci prisla ako odpoved ID objednavky s 36 znakmi
            if (resultData.length == 36) {
              //console.log("resultData.length 2: ", resultData.length);
              this.successCreateOrderDialog = true;
              this.createdOrderId = resultData;
              if (this.autoHideSuccessCreateOrderDialog == true) {
                setTimeout(() => {
                  this.successCreateOrderDialog = false;
                }, 2000);
              }
              if (this.autoHideSuccessCreateOrderClearDialog == true) {
                setTimeout(() => {
                  this.clearOrderData();
                  this.successCreateOrderDialog = false;
                }, 2000);
              }
              //overenie duplicitneho zadania objednavky tomu istemu zakaznikovi hned po sebe
              //iba pre taxisluzbu id 3
              if (this.taxiserviceId == 3) {
                if (this.lastClientId == this.orderCreateData.clientId) {
                  alert(this.$t("ConsoleOrderNew.msgDuplicateOrder")); //Duplicitné zadanie objednávky!
                }
                this.lastClientId = this.orderCreateData.clientId;
              }
            } else {
              alert(this.$t("ConsoleOrderNew.msgCreateOrderError")); //Chyba pri vytvorení objednávky!
            }
          } else {
            alert(this.$t("ConsoleOrderNew.msgCreateOrderError")); //Chyba pri vytvorení objednávky!
          }
          this.loadingCreateOrderButton = false;
          this.loadingCreateOrderDialogButton = false;
        })
        .catch((e) => {
          //this.animateProgressBar("error"), this.errors.push(e);
          //this.createOrderDialog = true;
          alert(this.$t("ConsoleOrderNew.msgCreateOrderError")); //Chyba pri vytvorení objednávky!
          this.loadingCreateOrderButton = false;
          this.loadingCreateOrderDialogButton = false;
        });
    },
    //Nakopirovat telefonne cislo do textu spravy
    addTextToNote(newText) {
      var originalText = this.orderCreateData.note;
      //Ak v poli nie je ziadny text, nepridavat medzeru na oddlenie textu
      if (originalText == "" || originalText == null) {
        this.orderCreateData.note = newText;
      } else {
        this.orderCreateData.note = originalText + " | " + newText;
      }
    },
    addRequestedTimeToNote() {
      var textString = "";
      //ak je casova objednavka, doplnit do poznámky "Čas: " + cas
      if (
        this.orderCreateData.type == "TIME" &&
        this.orderCreateData.requestedTime != null &&
        this.orderCreateData.requestedTime != ""
      ) {
        //check if requestedTime is date
        if (this.orderCreateData.requestedTime instanceof Date) {
          //console.log("requestedTime is date");
        } else {
          //console.log("requestedTime is not date");
          this.orderCreateData.requestedTime = new Date(
            this.orderCreateData.requestedTime
          );
        }
        textString +=
          this.orderCreateData.requestedTime.getHours() +
          ":" +
          //Ak je minuta 0, doplnit 0 na zaciatok
          (this.orderCreateData.requestedTime.getMinutes() < 10 ? "0" : "") +
          this.orderCreateData.requestedTime.getMinutes();
        this.addTextToNote(textString);
      }
    },
    addOrderDataNote() {
      var textString = "";
      //Ak neexistuje client, alebo nebol vybraty, ukoncit funkciu
      if (this.clientData == null || this.clientData.id == null) {
        alert(this.$t("ConsoleOrderNew.msgMissingClient"));
        return;
      }
      //telefon klienta
      if (this.clientData.phone != null && this.clientData.phone != "") {
        textString +=
          this.$t("ConsoleOrderNew.noteInsertClientPhone") +
          ": " +
          this.clientData.phone;
      }
      //ak je casova objednavka, doplnit do poznámky "Čas: " + cas
      if (
        this.orderCreateData.type == "TIME" &&
        this.orderCreateData.requestedTime != null &&
        this.orderCreateData.requestedTime != ""
      ) {
        //check if requestedTime is date
        if (this.orderCreateData.requestedTime instanceof Date) {
          //console.log("requestedTime is date");
        } else {
          //console.log("requestedTime is not date");
          this.orderCreateData.requestedTime = new Date(
            this.orderCreateData.requestedTime
          );
        }
        textString +=
          " | " +
          this.$t("ConsoleOrderNew.noteInsertTime") +
          ": " +
          this.orderCreateData.requestedTime.getHours() +
          ":" +
          //Ak je minuta 0, doplnit 0 na zaciatok
          (this.orderCreateData.requestedTime.getMinutes() < 10 ? "0" : "") +
          this.orderCreateData.requestedTime.getMinutes();
      }
      //Ak je objednavka firemna jazda, doplnit do poznámky "Firma: " + meno firmy
      if (this.orderCreateData.paymentType == "POSTPAID") {
        //find account name in this.accountAllList by this.orderCreateData.accountId
        var accountName = "";
        if (this.orderCreateData.accountId) {
          accountName = this.accountAllList.find(
            (x) => x.accountId == this.orderCreateData.accountId
          ).name;
          textString +=
            " | " +
            this.$t("ConsoleOrderNew.noteInsertAccount") +
            ": " +
            accountName;
        }
      }
      this.orderCreateData.note = textString;
    },
    //GOOGLE API
    addNewAddress(newPlace) {
      //Object adresy, ktory bude odoslany na server, detaultna struktura
      var newAddress = this.newAddress;
      //Object adresy, ktory prisiel z Google
      //console.log("newPlace", newPlace);
      //Rozhodnutie ci registrovat danu adresu na server alebo nie
      var registerNewAddress = true;
      //Temporary variables v strukture v ktorej prichadzaju z google API
      var latitude = "";
      var longitude = "";
      var displayName = "";
      var formattedAddress = "";
      var amenityName = "";
      var amenityType = "";
      var country = "";
      var country_code = "";
      var street_number = "";
      var route = "";
      //pozor, v US pisu inak oznacenie stvrte oproti EU, takze mozu prist 2 odlisne nazvy parametra
      var neighbourhood = "";
      var neighborhood = "";
      var sublocality = "";
      var sublocality_level_1 = "";
      var administrative_area_level_2 = "";
      var administrative_area_level_1 = "";
      var postal_code = "";
      var locality = "";
      var city = "";
      // ID = null vzdy pre nove adresy
      newAddress.id = null;
      //GPS skracujeme, aby neboli 1:1 s Google a je to aj odporucanie kvoli cachovaniu dat aby sa pracovalo s rozumnou toleranciu polohy
      //console.log("location.lat", newPlace.geometry.location.lat());
      //latitude = String(newPlace.geometry.location.lat()).substring(0, 9);
      latitude = newPlace.geometry.location.lat();
      //console.log("location.lng", newPlace.geometry.location.lng());
      //longitude = String(newPlace.geometry.location.lng()).substring(0, 9);
      longitude = newPlace.geometry.location.lng();
      //displayName aj formatted_address budu v tomto pripade tie iste udaje
      displayName = newPlace.formatted_address;
      formattedAddress = newPlace.formatted_address;
      //amenity type podla ikony nemozeme zistit, lebo pre reverse geo z google neprichadza, ale nechavam kvoli kompatibilite s ich strukturovu apu
      amenityName = newPlace.name ? String(newPlace.name) : null;
      //Typ objektu vieme zistit vlastne iba z typu ikony, preto si uchovame jej nazov
      amenityType = newPlace.icon
        ? String(newPlace.icon.split("/")[newPlace.icon.split("/").length - 1])
        : null;
      for (var u = 0; u < newPlace.address_components.length; u++) {
        var addr = newPlace.address_components[u];
        if (addr.types[0] == "plus_code") {
          //Nechceme registrovat adresy, ktore obsahuju plus_code - ten sa zatial pri urcovani adresy u nas nepouziva a take adresy maju skaredy format
          registerNewAddress = false;
          //console.log("plus_code");
        }
        if (addr.types[0] == "country") {
          country = addr.long_name;
          country_code = addr.short_name;
        }
        if (addr.types[0] == "street_number") {
          street_number = addr.long_name;
        }
        if (addr.types[0] == "route") {
          route = addr.long_name;
        }
        if (addr.types[0] == "neighborhood") {
          neighbourhood = addr.long_name;
        }
        if (addr.types[0] == "neighborhood") {
          neighborhood = addr.long_name;
        }
        if (addr.types[0] == "sublocality") {
          sublocality = addr.long_name;
        }
        if (addr.types[0] == "sublocality_level_1") {
          sublocality_level_1 = addr.long_name;
        }
        if (addr.types[0] == "administrative_area_level_2") {
          administrative_area_level_2 = addr.long_name;
        }
        if (addr.types[0] == "administrative_area_level_1") {
          administrative_area_level_1 = addr.long_name;
        }
        if (addr.types[0] == "postal_code") {
          postal_code = addr.long_name;
        }
        if (addr.types[0] == "locality") {
          locality = addr.long_name;
        }
      }
      //Premapovanie objektov a vynimky
      if (
        (locality == undefined || locality == "") &&
        sublocality_level_1 != undefined &&
        sublocality_level_1 != ""
      ) {
        locality = sublocality_level_1;
        //	$('#place_city').val(administrative_area_level_2);
      } else if (locality == undefined || locality == "") {
        locality = administrative_area_level_2;
        //	$('#place_city').val(locality);
      }
      //	$('#place_country').val(country);
      //ošetrenie správneho uvedenia mesta napr. pre Bratislavu, lebo google občas vracia iba názvy štvrtí
      if (administrative_area_level_2 != undefined) {
        if (
          administrative_area_level_2.substring(0, 10) == "Bratislava" &&
          (locality == "Ružinov" ||
            locality == "Petržalka" ||
            locality == "Rača" ||
            locality == "Staré Mesto" ||
            locality == "Vrakuňa" ||
            locality == "Podunajské Biskupice" ||
            locality == "Nové Mesto" ||
            locality == "Vajnory" ||
            locality == "Karlova Ves" ||
            locality == "Dúbravka" ||
            locality == "Lamač" ||
            locality == "Devín" ||
            locality == "Devínska Nová Ves" ||
            locality == "Záhorská Bystrica" ||
            locality == "Jarovce" ||
            locality == "Rusovce" ||
            locality == "Čunovo")
        ) {
          city = "Bratislava";
        } else if (
          administrative_area_level_2.substring(0, 10) == "Bratislava" &&
          (sublocality == "Bratislava" ||
            sublocality == "Bratislava I" ||
            sublocality == "Bratislava II" ||
            sublocality == "Bratislava III" ||
            sublocality == "Bratislava IV" ||
            sublocality == "Bratislava V")
        ) {
          city = "Bratislava";
        } else {
          city = locality;
        }
      } else {
        city = locality;
      }

      // ID = null vzdy pre nove adresy
      newAddress.id = null;
      newAddress.latitude = latitude;
      newAddress.longitude = longitude;
      newAddress.displayName = displayName;
      newAddress.formattedAddress = formattedAddress;
      newAddress.country = country;
      newAddress.countryCode = country_code;
      newAddress.amenityName = amenityName;
      newAddress.amenityType = amenityType;
      newAddress.city = city;
      newAddress.cityDistrict = locality;
      newAddress.county = administrative_area_level_2;
      newAddress.houseNumber = street_number;
      newAddress.neighbourhood = neighbourhood ? neighbourhood : neighborhood;
      newAddress.postcode = postal_code;
      newAddress.road = route;
      newAddress.state = administrative_area_level_1;
      newAddress.suburb = sublocality;
      //Zaevidovanie adresy na server
      if (registerNewAddress == true) {
        //console.log("new address", newAddress.formattedAddress);
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/place`,
            newAddress
          )
          .then((response) => {
            //console.log(response.data);
            var responseData = response.data;
            if (responseData.message == "ALREADY_EXISTS") {
              console.log(
                "Zaevidovanie adresy - NOK - ALREADY_EXISTS",
                newAddress.formattedAddress
              );
            } else {
              //console.log("Zaevidovanie adresy - OK",newAddress.formattedAddress);
            }
          })
          .catch((e) => {
            console.log(e.message);
            console.log(
              "Zaevidovanie adresy - ERROR",
              newAddress.formattedAddress
            );
          });
      }
    },
    //GEOCODE EARTH API
    addNewAddressGeocodeEarth(newPlace) {
      //Object adresy, ktory bude odoslany na server, detaultna struktura
      var newAddress = this.newAddress;
      //Object adresy, ktory prisiel z Google
      //console.log("newPlace", newPlace);
      //Rozhodnutie ci registrovat danu adresu na server alebo nie
      var registerNewAddress = true;
      // ID = null vzdy pre nove adresy
      newAddress.id = null;
      var latitude = newPlace.geometry
        ? newPlace.geometry.coordinates[1]
        : null;
      var longitude = newPlace.geometry
        ? newPlace.geometry.coordinates[0]
        : null;
      var displayName = newPlace.properties.label;
      var formattedAddress = newPlace.properties.label;
      var amenityName = newPlace.properties.name
        ? String(newPlace.properties.name)
        : null;
      var amenityType = null; // Neprichadza z Geocode Earth API, nastavujeme na null
      var country = newPlace.properties.country;
      var country_code = newPlace.properties.country_code;
      var street_number = newPlace.properties.housenumber;
      var route = newPlace.properties.street;
      //pozor, v US pisu inak oznacenie stvrte oproti EU, takze mozu prist 2 odlisne nazvy parametra
      var neighbourhood = null; // Neprichadza z Geocode Earth API, nastavujeme na null
      var neighborhood = null; // Neprichadza z Geocode Earth API, nastavujeme na null
      var sublocality = null; // Neprichadza z Geocode Earth API, nastavujeme na null
      var sublocality_level_1 = ""; // Neprichadza z Geocode Earth API, nastavujeme na null
      var administrative_area_level_2 = newPlace.properties.county;
      var administrative_area_level_1 = newPlace.properties.region;
      var postal_code = newPlace.properties.postalcode;
      var locality = newPlace.properties.locality;
      var city = newPlace.properties.locality;

      //Z adresy odstranit supisne cislo z adresy. Tie cisla su s lomitkom a spravidla dlhsie ako 6 znakov (1234/9)
      if (street_number) {
        if (street_number.length > 5) {
          //supi
          var slashPosition = street_number.indexOf("/");
          if (slashPosition > 0) {
            var street_number_array = street_number.split("/");
            if (street_number_array.length == 2) {
              var street_number_orientacne = street_number_array[1].trim();
              //nahrať street_number v displayName
              displayName = displayName.replace(
                street_number,
                street_number_orientacne
              );
            }
          }
        }
      }

      // ID = null vzdy pre nove adresy
      newAddress.id = null;
      newAddress.latitude = latitude;
      newAddress.longitude = longitude;
      newAddress.displayName = displayName;
      newAddress.formattedAddress = formattedAddress;
      newAddress.country = country;
      newAddress.countryCode = country_code;
      newAddress.amenityName = amenityName;
      newAddress.amenityType = amenityType;
      newAddress.city = city;
      newAddress.cityDistrict = locality;
      newAddress.county = administrative_area_level_2;
      newAddress.houseNumber = street_number;
      newAddress.neighbourhood = neighbourhood ? neighbourhood : neighborhood;
      newAddress.postcode = postal_code;
      newAddress.road = route;
      newAddress.state = administrative_area_level_1;
      newAddress.suburb = sublocality;
      //Zaevidovanie adresy na server
      if (registerNewAddress == true) {
        //console.log("new address", newAddress.formattedAddress);
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/place`,
            newAddress
          )
          .then((response) => {
            //console.log(response.data);
            var responseData = response.data;
            if (responseData.message == "ALREADY_EXISTS") {
              console.log(
                "Zaevidovanie adresy - NOK - ALREADY_EXISTS",
                newAddress.formattedAddress
              );
            } else {
              //console.log("Zaevidovanie adresy - OK",newAddress.formattedAddress);
            }
          })
          .catch((e) => {
            console.log(e.message);
            console.log(
              "Zaevidovanie adresy - ERROR",
              newAddress.formattedAddress
            );
          });
      }
    },
    showOrderDetail(orderId) {
      this.$emit("showOrderDetailByIdFromNewOrder", orderId);
      //this.$store.state.consoleOrderId = orderId;
    },
    addToPhoneHistory(phone) {
      //ak je phone prázdne, tak ho nepridavaj
      if (phone == undefined || phone == "") {
        return;
      }
      var phoneHistoryStr = this.getCookie("phoneCallHistory");
      let phoneHistory = [];
      // Parse existing history or create new
      if (phoneHistoryStr && phoneHistoryStr !== "") {
        try {
          phoneHistory = JSON.parse(phoneHistoryStr);
          if (!Array.isArray(phoneHistory)) phoneHistory = [];
        } catch (e) {
          console.error("Error parsing phone history:", e);
          phoneHistory = [];
        }
      }
      // Find existing entry
      const index = phoneHistory.findIndex((item) => item.phone === phone);
      // Remove existing entry if found
      if (index > -1) {
        phoneHistory.splice(index, 1);
      }
      // Add to beginning with current timestamp
      phoneHistory.unshift({
        phone: phone,
        usedAt: new Date().toISOString(),
      });
      // Limit to 10 entries
      if (phoneHistory.length > 10) {
        phoneHistory.pop();
      }
      // Save to cookie as JSON
      this.setCookie("phoneCallHistory", JSON.stringify(phoneHistory), 1);
      // Update component's phoneHistory array for display
      this.updatePhoneHistoryDisplay(phoneHistory);
    },
    updatePhoneHistoryDisplay(phoneHistoryData) {
      this.phoneHistory = phoneHistoryData.map((item) => {
        const date = new Date(item.usedAt);
        const formattedTime =
          date.getHours() +
          ":" +
          (date.getMinutes() < 10 ? "0" : "") +
          date.getMinutes();

        return {
          phone: item.phone,
          usedAt: formattedTime,
          originalDate: date, // Store the actual date object for additional formatting
        };
      });
    },
    getCookieToPhoneHistory() {
      try {
        const phoneHistoryStr = this.getCookie("phoneCallHistory");
        if (phoneHistoryStr && phoneHistoryStr !== "") {
          const phoneHistoryParsed = JSON.parse(phoneHistoryStr);
          if (Array.isArray(phoneHistoryParsed)) {
            this.updatePhoneHistoryDisplay(phoneHistoryParsed);
          } else {
            this.phoneHistory = [];
          }
        } else {
          this.phoneHistory = [];
        }
      } catch (e) {
        console.error("Error loading phone history:", e);
        this.phoneHistory = [];
      }
    },
    addRandomNumberToPhoneHistory() {
      //pridat nahodne cislo do zoznamu telefonnych cisel
      var randomNumber = Math.floor(Math.random() * 10);
      var randomPhone = this.defaultPhoneDialCode
        ? this.defaultPhoneDialCode
        : "+421";
      randomPhone += "00000000" + randomNumber;
      this.randomPhoneNumber = randomPhone;
    },
    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        var secondsExpired = Math.round(daysToLive * 24 * 60 * 60);
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie += "; max-age=" + secondsExpired + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
      }
    },
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },
    findCountryNameByPhoneNumber(phoneNumber) {
      const country = PhoneCountry.phoneCountry.find(({ dialCode }) =>
        phoneNumber.startsWith(`+${dialCode}`)
      );
      //return iso2 and dialCode
      //console.log("findCountryNameByPhoneNumber", country);
      return country && country != undefined ? country : null;
    },
    getDefaultCountryFromDispatchPhoneNumber(phone) {
      var country = this.findCountryNameByPhoneNumber(phone);
      if (
        country.iso2 != undefined &&
        country.iso2 != null &&
        country.iso2 != ""
      ) {
        country.iso2 = country.iso2.toLowerCase();
      }
      this.defaultPhoneCountryCode = country.iso2;
      this.defaultPhoneDialCode = "+" + country.dialCode;
      //if country is not identified, then return false
      //console.log("defaultPhoneCountryCode", this.defaultPhoneCountryCode);
      //console.log("PhoneMobileEnums.mobilePrefixes", mobilePrefixes);
    },
    notifyClientPhoneCountryCode(phone) {
      //console.log("notifyClientPhoneCountryCode phone", phone);
      this.phoneCountryName = "";
      //vyhladaj country code podla cisla
      var country = this.findCountryNameByPhoneNumber(phone);
      //console.log("notifyClientPhoneCountryCode country", country);
      //console.log("defaultPhoneCountryCode", this.defaultPhoneCountryCode);
      if (country != null) {
        //ak sa nezhoduje s default country code, tak upozorni (porovnat lowercase)
        if (
          country.iso2 != undefined &&
          country.iso2 != null &&
          country.iso2 != ""
        ) {
          country.iso2 = country.iso2.toLowerCase();
        }
        if (country.iso2 != this.defaultPhoneCountryCode) {
          this.phoneCountryAlert = true;
          this.phoneCountryName = country.name;
          //console.log("notifyClientPhoneCountryCode phoneCountryAlert ALERT");
        } else {
          this.phoneCountryAlert = false;
          //console.log("notifyClientPhoneCountryCode phoneCountryAlert NOALERT");
        }
      } else {
        this.phoneCountryAlert = false;
        //console.log("notifyClientPhoneCountryCode phoneCountryAlert NOALERT");
      }
      //console.log("notifyClientPhoneCountryCode iso2", country.iso2);
      //mobile prefixes are in format eg for slovakia:  "421": ["948", "949"] or Austria "43": ["650", "660", "664"], we know country prefix and we need from phone number find, if is mobile or not
      //if mobile, then we need to show alert
      //var mobilePrefixes = mobilePrefixes;
      if (mobilePrefixes[country.dialCode] != undefined) {
        var isMobile = false;
        var mobilePrefixesArray = mobilePrefixes[country.dialCode];
        for (var i = 0; i < mobilePrefixesArray.length; i++) {
          if (
            phone.startsWith(`+${country.dialCode}${mobilePrefixesArray[i]}`)
          ) {
            isMobile = true;
            break;
          }
        }
        if (isMobile) {
          this.phoneLandlineAlert = false;
          //console.log("notifyClientPhoneCountryCode phoneLandlineAlert NOALERT");
        } else {
          this.phoneLandlineAlert = true;
          //console.log("notifyClientPhoneCountryCode phoneLandlineAlert ALERT");
        }
      }
    },
    checkClientMark() {
      //console.log("checkClientMark");
      //Upozornit dispecera na to, ze klient ma MARK blocked alebo debtor
      if (this.clientData.mark == "BLOCKED") {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("ConsoleOrderNew.msgClientBlocked"); //"Klient je zablokovaný!"
        this.snackbar.color = "error";
        alert(this.$t("ConsoleOrderNew.msgClientBlocked")); //"Klient je zablokovaný!"
      } else if (this.clientData.mark == "DEBTOR") {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("ConsoleOrderNew.msgClientDebtor"); //"Klient je dlžník!"
        this.snackbar.color = "warning";
      } else if (this.clientData.mark == "VIP") {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("ConsoleOrderNew.msgClientVip"); //"Klient je VIP!"
        this.snackbar.color = "success";
      }
    },

    getDefaultStand() {
      var defaultStandPosition = this.getCookie("defaultStandPosition");
      if (defaultStandPosition && defaultStandPosition != null) {
        if (defaultStandPosition.length > 10) {
          defaultStandPosition = JSON.parse(defaultStandPosition);

          if (
            defaultStandPosition.lat != null &&
            defaultStandPosition.lng != null &&
            defaultStandPosition.lat != "" &&
            defaultStandPosition.lng != ""
          ) {
            //console.log("defaultStandPosition from cookie is not null");
            this.defaultStand.latitude = defaultStandPosition.lat;
            this.defaultStand.longitude = defaultStandPosition.lng;
            return;
          }
        }
      }
      this.getStands();
    },

    getStatusColor(status) {
      return this.StatusColor[status];
    },
    getExtensionColor(index) {
      switch (index) {
        case 0:
          return "green";
        case 1:
          return "blue";
        case 2:
          return "amber";
        case 3:
          return "purple";
        case 4:
          return "red";
        case 5:
          return "lime";
        case 6:
          return "cyan";
        case 7:
          return "teal";
        case 8:
          return "pink";
        case 9:
          return "brown";
        case 10:
          return "orange";
        default:
          return "grey";
      }
    },
    validateRequestedTime(dateToCheck) {
      if (dateToCheck < new Date()) {
        alert(this.$t("ConsoleOrderNew.msgTimeInPastError"));
        return false;
      }
      if (dateToCheck < new Date(new Date().getTime() + 5 * 60000)) {
        alert(this.$t("ConsoleOrderNew.msgTimeTooSoonError"));
        return false;
      }
      if (dateToCheck > new Date().setDate(new Date().getDate() + 21)) {
        alert(this.$t("ConsoleOrderNew.msgTimeTooLateError"));
        return false;
      }
      return true;
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshTimer);
  },
};
</script>
